.detail {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}

.concepts {
  margin: 0 12px 10px 12px;
  color: #100C18;
}

.orderedList{
  line-height: 2em;
  font-size: 1.4em;
  font-weight: 300;
  color: #555;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3px 0;
}

.concept {
  padding: 6px 12px;
  transition: 200ms;
  border-radius: 10000em;
}
.hoverable .concept {
  cursor: pointer;
}

.createConcept {
  font-size: 12px;
  transition: 200ms;
  cursor: pointer;
  padding: 6px 12px;
}
.highlighted .createConcept {
  background: #F6F1F6;
  border-radius: 1000em;
}

.conceptIcon {
  height: 10px;
  width: auto;
  --tone1: var(--color);
  margin-left: 6px;
}

.hoverable .concept:hover, .concept.selected {
  background: var(--primary-color);
  color: #fff;
  --color: #fff;
}

.row.selected .concept {
  background: var(--background);
  color: var(--color);
}

.row.highlighted .concept {
  background: var(--highlight-background, #F6F1F6);
}
.row.hoverable .concept:hover {
  background: var(--highlight-background, var(--primary-color));
}

.botIcon {
  background: var(--primary-color);
  fill: #fff;
  cursor: pointer;
  display: inline-block;
  height: 18px;
  width: 18px;
  border-radius: 10px;
  padding: 1px 2px;
  vertical-align: -4px;
  position: relative;
}

.botIcon svg {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



.deleteButton {
  margin: 20px;
  padding: 8px;
  border-radius: 4px;
  background: #EF5A46;
  text-align: center;
  color: #FBDEDA;
  cursor: pointer;
  transition: 200ms;
}

.deleteButton:hover {
  background: #BF4838;
}



.input {
  font-size: 12px;
  background: #F1F1F1;
  border: 1px solid #D6D6D6;
  border-radius: 5px;
  margin: 10px 12px;
  padding: 9px 10px;
  outline: 0;
  transition: 200ms;
}

.input:focus {
  background: var(--accent-input);
}


.ratings {
  display: flex;
  flex-direction: column;
  margin: 10px;
  align-items: flex-start;
}

.rating {
  border-radius: 10000em;
  color: var(--color);
  background: var(--background);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  margin: 2px;
  transition: 200ms;
  font-size: 18px;
  cursor: pointer;
  font-weight: 100;
}

.ratingButton {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--color);
  color: #fff;
  margin-right: 6px;
}

.rating:hover {
  background: var(--hover-background);
}


.trashIcon {
  height: 10px;
  width: auto;
  margin-left: 6px;
  cursor: pointer;
}

.trashIcon:hover {
  --icon-tone1: red;
  --icon-tone2: red;
}


.range {
  padding: 6px 12px;
  transition: 200ms;
  border-radius: 10000em;
  font-size: 12px;
  color: var(--color);
  background: var(--background);
}

.classOverrides {
  margin: 0 12px;
}