.container {
  display: flex;
  flex-direction: column;
  color: var(--text);
  position: relative;
}

.border {
  position: relative;
  display: flex;
  flex-direction: row;
  grid-auto-flow: column;
  border-radius: 0.25em;
  padding: 0.375em 0.5em;
  background: #fff;
  transition: 300ms;
  border: 1px solid var(--input-border);
  text-decoration: none;
  cursor: pointer;
  align-items: center;
}

.title {
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  z-index: 1;
  padding-bottom: 0.2em;
  color: var(--text);
  transition: 300ms;
  align-self: flex-start;
  width: 100%;
}
.title span {
  font-size: 0.7em;
}

.input,
.input:-webkit-autofill,
.input:-internal-autofill-previewed,
.input:-internal-autofill-selected {
  display: block;
  outline: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  border: none;
  transition: 300ms;
  flex-basis: 0;
  flex-grow: 1;
  font-size: 16px;
  padding: 0;
  margin: 0;
  line-height: 23px;
}

.input:-webkit-autofill, .input:-internal-autofill-previewed, .input:-internal-autofill-selected, input:-webkit-autofill::first-line {
  font-size: 16px !important;
  padding: 0 !important;
  margin: 0 !important; 
  line-height: 23px !important;
}
.input:-webkit-autofill, .input:-internal-autofill-previewed, .input:-internal-autofill-selected {
  box-shadow: 0 0 0 2em #fff inset;
}

.preTextIcon {
  fill: var(--text);
}

.warningIcon {
  height: 0.75em;
  fill: var(--error);
  align-self: center;
  flex-shrink: 0;
  flex-grow: 0;
}

.closeIcon {
  height: 0.875em;
  width: auto;
  fill: var(--text);
  transition: 300ms;
}

.closeIcon:hover {
  fill: var(--icon-hover-tone1);
  transform: scale(1.2);
}

.container.error {
  color: var(--error-text);
}
.container.error .border {
  background: var(--error-input);
  border-color: var(--error-border);
  box-shadow: 0 0 2px var(--error);
}
.container.error .border.focused {
  box-shadow: 0 0 2px var(--error);
}
.container.error .border .input:-webkit-autofill {
  box-shadow: 0 0 0 2em var(--error-input) inset;
}
.container.error .title {
  color: var(--error-text);
}

.container.disabled .border {
  background: var(--accent-disabled);
}
.container.disabled .border:hover {
  box-shadow: none;
}

.container.focused .border {
  background: var(--accent-input);
  border-color: var(--primary-w90-color);
  box-shadow: 0 0 2px var(--primary-color);
}
.container.focused .border .input:-webkit-autofill {
  box-shadow: 0 0 0 2em var(--accent-input) inset;
}

.border svg {
  margin-left: 6px;
}