.scrollable {
  background: #fff;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 0;
}

.scrollable:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  background: radial-gradient(farthest-side at 50% 0%, rgba(99, 69, 146, 0.3), rgba(99, 69, 146, 0)) 100% 0;
  background-repeat: no-repeat;
  background-size: 100% 5px;
  height: 8px;
  z-index: 1;
}
.scrollable:after {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  background: radial-gradient(farthest-side at 50% 100%, rgba(99, 69, 146, 0.3), rgba(99, 69, 146, 0)) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 5px;
  width: inherit;
  height: 8px;
  z-index: 1;
}

.scrollableContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  height: 100%;
  box-sizing: border-box;
}

.review {
  margin: 10px 14px;
  position: relative;
}

.sentence {
  background: var(--highlight-color);
  border-bottom: 1px solid var(--highlight-color);
  padding: 0.1em 0.6em 0.035em;
  color: rgb(56, 50, 66);
  line-height: 1.7em;
  border-left: 3px solid var(--highlight-color);
  transition: 300ms;
  cursor: pointer;

  &:hover{
    background-color: hsla(262, 37%, 31%, 0.08);

  }
}

.matchingWord {
  border-bottom: 1px solid #d3bbf991;
  color: #7d5bb3;
  background-color: hsla(262, 37%, 31%, 0.1);
}

.text {
  margin-bottom: 8px;
  margin-top: 16px;
}

.extract {
  color: #555;
}