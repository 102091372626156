.container {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--background-color);
  z-index: 10000;
  color: #fff;
  padding: 0.5em;
  border-radius: 0.5em;
  pointer-events: none;
  filter: drop-shadow(0 2px 4px var(--shadow-color));
  white-space: nowrap;
  font-size: 10px;
}

.arrow {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  background: transparent;
  border-color: transparent;
}

.arrow.top {
  top: 100%;
  left: 50%;
  border-left: var(--arrow-size) solid transparent;
  border-right: var(--arrow-size) solid transparent;
  border-top: var(--arrow-size) solid var(--background-color);
}
.arrow.right {
  border-width: var(--arrow-size) var(--arrow-size) var(--arrow-size) 0;
  top: 50%;
  left: 0;
  border-top: var(--arrow-size) solid transparent;
  border-bottom: var(--arrow-size) solid transparent;
  border-right: var(--arrow-size) solid var(--background-color);
  
}
.arrow.bottom {
  border-width: 0 var(--arrow-size) var(--arrow-size) var(--arrow-size);
  top: 0%;
  left: 50%;
  border-left: var(--arrow-size) solid transparent;
  border-right: var(--arrow-size) solid transparent;
  border-bottom: var(--arrow-size) solid var(--background-color);
}
.arrow.left {
  border-width: var(--arrow-size) 0 var(--arrow-size) var(--arrow-size);
  top: 50%;
  right: 0;
  border-top: var(--arrow-size) solid transparent;
  border-bottom: var(--arrow-size) solid transparent;
  border-left: var(--arrow-size) solid var(--background-color);
}