.previewCardWrap {
  background: #fff;
  margin: 6px 10px;
  box-shadow: 0 1px 6px rgba(0,0,0,0.07);
  border-radius: 4px;
  transition: 400ms;
  overflow: hidden;
}

.previewCard {
  padding: 22px;
  background: linear-gradient(0deg,#8262AF, #9A7BC4);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.header {
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 12px;
  font-family: 'tiempos';
}

.text {
  color: #fff;
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  text-align: center;
  max-width: 28em;
}

.button, .button:link, .button:visited {
  padding: 12px 26px;
  background: #47326C;
  color: #fff;
  border-radius: 10000em;
  margin-top: 30px;
  outline: 0;
  border: none;
  cursor: pointer;
  transition: 200ms;
}
.button:hover, .button:link:hover, .button:visited:hover {
  box-shadow: 1px 2px 1px 0 rgb(45, 30, 70);
  transform: translate(0, -2px);
  color: #fff;
}

.previewCardLabel {
  font-size: 10px;
  font-weight: normal;
  position: absolute;
  padding: 6px 150px;
  background: #47326C;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%) rotate(-45deg) translate(0, 200%);
}