.section {
  padding: 1em 1.25em;
  margin: .3em 0;
  border: 1px solid #eee;
  background: none;
  border-radius: 0.5em;
  display: grid;
  grid-template-columns: 2.6em 1fr;
  grid-auto-flow: row;
  background: #fff;
  grid-gap: 0.5em 1em;
  transition: 300ms;
  position: relative;
}

.hoverable {
  cursor: pointer;
}
.hoverable:hover {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.minimizeIcon {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 14px;
  height: 14px;
  fill: #666;
  cursor: pointer;
  transition: 300ms;
}
.minimizeIcon svg {
  width: 100%;
  height: 100%;
}
.minimizeIcon:hover {
  fill: var(--primary-color);
}

.sectionIcon {
  width: 2.5em;
  height: 2.5em;
  box-sizing: border-box;
  background: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-start: 1;
  grid-column-end: 2;
  color: #fff;
  fill: #fff;
  flex-shrink: 0;
  font-weight: bold;
  --icon-tone1: #fff;
  --icon-tone2: #fff;
  --icon-tone1-opacity: 1;
  --icon-tone2-opacity: 1;
}

.sectionIcon svg {
  width: 50%;
  height: 50%;
}

.sectionHeader {
  display: flex;
  box-sizing: border-box;
  grid-column-start: 2;
  grid-column-end: -1;
  color: #444;
  align-items: center;
  font-weight: 800;
  letter-spacing: -0.03em;
  font-size: 1.2em;
  
}

.sectionContent {
  grid-column-start: 2;
  grid-column-end: span 1;
}


a.section .sectionIcon {
  background: var(--primary-w90-color);
}

a.section .sectionHeader {
  color: #8c8c8c;
}