.container.container {
  border-radius: 35px;
  padding: .8em 1em;
  background: var(--primary-color);
  color: var(--text-color);
  transition: 300ms;
  text-decoration: none;
  border: none;
  outline: 0;
  display: inline-block;
}
.container.hoverable:not(.disabled) {
  cursor: pointer;
}
.container.hoverable:hover:not(.disabled) {
  /* background: var(--primary-b90-color); */
  color: white;
  cursor: pointer;
  box-shadow: 0 0 5px var(--primary-color);
}
.container.disabled {
  background: var(--accent-disabled);
  color: var(--text-color);
}

.container.disabled:hover {
  box-shadow: none;
}
.container svg {
  height: 0.75em;
  width: auto;
}

.outlineContainer.outlineContainer {
  border-radius: 0.25em;
  padding: 0.375em 0.5em 0.3em;
  background: var(--button-background, var(--accent-hover));
  color: var(--button-color, var(--primary-color));
  transition: 300ms;
  text-decoration: none;
  outline: 0;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.9em;
}
.outlineContainer.hoverable:not(.disabled)  {
  cursor: pointer;
}
.outlineContainer.hoverable:hover:not(.disabled)  {
  box-shadow: 0 0 5px var(--button-hover-color, var(--primary-color));
}
.outlineContainer.disabled {
  background: var(--button-disabled, var(--accent-disabled));
}
.outlineContainer.disabled:hover {
  box-shadow: none;
}
.outlineContainer svg {
  height: 0.75em;
  width: auto;
}

.icon {
  height: 1em;
  width: auto;
  fill: var(--button-color, var(--primary-color));
  margin-right: 0.3em;
}