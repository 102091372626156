.containerWrap {
  overflow: hidden;
  background: var(--primary-w90-color);
  min-height: 100vh;
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  grid-auto-rows: max-content;
  grid-gap: 8px;
  padding: 1em;
  flex-basis: 0;
  flex-grow: 1;

  padding-top: var(--header-height);
  padding-bottom: var(--footer-height);
  margin-left: var(--nav-width);
  transition: 200ms;
}

.addGroup {
  grid-column-start: 1;
  grid-column-end: -1;
}

.card {
  background: #fff;
  border-radius: 6px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-auto-rows: max-content;
  padding: 8px;
}

.rowHeader {
  grid-column-start: 1;
  grid-column-end: -1;
  color: var(--text);
  margin-top: 10px;
  font-weight: 600;
}

.header {
  color: var(--text);
  grid-column-start: 1;
  grid-column-end: -1;
  border-bottom: 1px solid var(--text);
  margin-bottom: 4px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.deleteIcon {
  cursor: pointer;
  
}
.deleteIcon:hover {
  --icon-tone1: var(--primary-color);
  --icon-tone1-opacity: 1;
}

.cardConcept {
  color: var(--text);
  cursor: pointer;
  transition: 200ms;
  border-radius: 4px;
  padding: 4px;
}

.cardConcept:hover {
  background: var(--primary-color);
  color: #fff;
}

.addConceptsButton {
  color: var(--text);
  grid-column-start: 1;
  grid-column-end: -1;
  border-radius: 4px;
  border: none;
  background: #eee;
  color: var(--text);
  transition: 200ms;
  padding: 4px 6px;
  font-size: 10px;
  cursor: pointer;
  margin-top: 10px;
  outline: 0;
}

.addConceptsButton:hover {
  background: var(--primary-color);
  color: #fff;
}

.conceptsHeader {
  font-size: 12px;
  color: var(--text);
  grid-column-start: 1;
  grid-column-end: -1;
  margin-top: 8px;
  margin-left: 4px;
}

.provider {
  color: var(--text);
  font-size: 14px;
}