.tooltip {
  border-radius: 5px;
  font-size: 12px;
  padding: 0.5em;
  filter: drop-shadow(0 2px 4px rgba(0,0,0,0.5));
}

.container {
  visibility: visible;
  
}

.loadingContainer {
  min-height: 100vh;
}