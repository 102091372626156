.container {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  width: 100%;
  flex-basis: 0;
  flex-grow: 1;
}

.demoButton, .demoButton:link, .demoButton:visited {
  font-size: 14px;
  color: #fff;
  padding: 12px 24px;
  background: #47326C;
  border-radius: 10000em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  outline: 0;
  border: none;
  cursor: pointer;
  transition: 200ms;
}

.demoButton:hover, .demoButton:link:hover, .demoButton:visited:hover {
  box-shadow: 1px 2px 1px 0 rgb(45, 30, 70);
  transform: translate(-50%, calc(-50% - 2px));
  color: #fff;
}

.grid {
  position: relative;
  display: grid;
  grid-template-rows: min-content;
  grid-auto-rows: min-content;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  flex-basis: 0;
  flex-grow: 1;
  grid-template-columns: repeat( var(--column-count), minmax(min-content, max-content) );
}

.logoHeader {
  padding: 0.5em 0;
  background: #fff;
  border-bottom: 1px solid var(--background);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-content: stretch;
  justify-content: stretch;
  transition: 300ms;
  top: 0;
  left: 0;
  position: sticky;
  position: -webkit-sticky;
  font-size: 14px;
  z-index: 11;
  left: 0;
  top: 0;
  border-bottom-color: transparent;
}

.labelRow {
  grid-column-end: -1;
  grid-column-start: 1;
  padding: 5em 1em 1em 1em;
  color: var(--text);
  left: 0;
}

.headerRow {
  display: contents;
}

.headerRow > * {
  z-index: 11;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: var(--content);
  
}

.headerRow > *:first-child {
  z-index: 12;
  position: sticky;
  position: -webkit-sticky;
}

.row {
  display: contents;
}

.row:nth-child(even) > * {
  background: #F6F6F6;
}
.row:nth-child(odd) > * {
  background: var(--content);
}

.row > *:first-child {
  z-index: 10;
  position: sticky;
  position: -webkit-sticky;
}
.headerRow > *:nth-child(1), .row > *:nth-child(1) {
  border-right: 1px solid var(--background);
}

.headerRow > *:first-child, .row > *:first-child {
  padding-left: 1em;
  left: 0;
  top: 0;
}

/* .row > *:first-child {
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
} */

.row > *:last-child {
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
}

/* .headerRow > *:nth-child(1), .row > *:nth-child(1) {
  left: -16px;
} */