.content {
  background: linear-gradient(0deg, var(--primary-w20-color), var(--primary-w40-color));
  color: var(--text-color);
  max-width: 100%;
  width: 650px;
}

.content p {
  font-size: 20px;
  text-align: center;
}

.content h4 {
  text-align: center;
  letter-spacing: -0.02em;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.smiles {
  display: flex;
  flex-direction: row;
  margin: 10px -5px 30px -5px;
  justify-content: center;
}
.smile {
  height: 63px;
  width: auto;
  margin: 5px;
  cursor: pointer;
  display: block;
  --stroke-width: 2.2;
}
.smile svg {
  display: block;
  height: 100%;
  width: auto;
  transition: 200ms;
}

.smile input[type="radio"] {
  display: none;
}

.hasValue svg {
  transform: scale(0.9);
  opacity: 0.8;
}

.smile input[type="radio"]:checked + svg, .smile:hover svg {
  transform: scale(1.15);
  opacity: 1;
}

.smile input[type="radio"]:checked + svg{
  margin: 0 3px;
}

.hr {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0));
}

.textarea {
  display: block;
  border: 1px solid var(--primary-color);
  font-size: 16px;
  line-height: 1.5em;
  border-radius: 12px;
  outline: 0;
  background: var(--primary-w90-color);
  color: var(--primary-color);
  font-weight: 500;
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  resize: none;
  transition: 200ms;
  min-height: 200px;
}
.textarea:focus {
  box-shadow: 0 0 0 2px var(--primary-b20-color), 0 0 12px 0 var(--primary-b02-color);
}

.submit {
  background: var(--primary-color);
  padding: 12px 26px;
  border-radius: 10000em;
  color: var(--text-color);
  border: none;
  outline: 0;
  cursor: pointer;
  transition: 200ms;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
  align-self: flex-end;
}

.submit.disabled {
  cursor: not-allowed;
}

.submit:not(.disabled):hover {
  box-shadow: 1px 2px 1px 0 rgb(45, 30, 70);
  transform: translate(0, -2px);
}

.submit.disabled {
  background: var(--primary-w40-color);
}

.loading {
  height: 50px; 
}

.thankyou {
  display: flex;
  flex-direction: column;
}

.thankyouMessage {
  font-size: 24px;
  text-align: center;
  align-self: center;
  padding: 50px 0 20px 0;
}

.error {
  color: #000;
}
