.container {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 30px 20px;
  z-index: var(--z-index-hud);
}

.container button {
  border-radius: 100em 0 0 100em;
  border: none;
  margin: 0;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.09);
  fill: var(--primary-color, currentColor);
  background: var(--text-color, #fff);
  padding: 12px 12px 12px 17px;
  transition: 200ms;
  outline: 0;
}

.container button.selected {
  animation-name: buttonBlinker;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-duration: var(--animation-time, 500ms);
  animation-fill-mode: none;
  animation-direction: normal;
}
.container button:disabled {
  opacity: 0.2;
}

.container button:not(:disabled) {
  cursor: pointer;
}

.container button + button {
  border-radius: 0 100em 100em 0;
  fill: var(--text-color, #fff);
  background: var(--primary-color, currentColor);
  padding: 12px 17px 12px 12px;
}

.container button + button.selected {
  animation-name: nextButtonBlinker;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-duration: var(--animation-time, 500ms);
  animation-fill-mode: none;
  animation-direction: normal;
}

.container button svg {
  display: block;
}

.right {
  transform: rotate(180deg);
}

@keyframes buttonBlinker {
  50% {
    fill: var(--text-color, '#fff');
    background: var(--primary-w90-color, currentColor);
  }
  100% {
    fill: var(--primary-color, currentColor);
    background: var(--text-color, #fff);
  }
}

@keyframes nextButtonBlinker {
  50% {
    fill: var(--text-color, '#fff');
    background: var(--primary-w90-color, currentColor);
  }
  100% {
    fill: var(--text-color, #fff);
    background: var(--primary-color, currentColor);
  }
}