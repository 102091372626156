.button {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #EBEBEB;
  box-shadow: 0 1px 3px rgba(0,0,0,0.5);
  fill: #B0B0B0;
  transition: 300ms;
  z-index: 1;
  font-size: 11px;
  transform: translate(50%, 50%);
}

.button svg{
  width: auto;
  height: 50%;
}

.button:hover {
  background: var(--primary-color);
  fill: #fff;
}

.highlight {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 4px;
  border: 2px dashed var(--primary-color);
  background: transparent;
  pointer-events: none;
  z-index: 0;
  opacity: 0;
  transition: 300ms;
}

.button:hover + .highlight {
  opacity: 1;
}