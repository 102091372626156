.container {
  background: #fff;
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: absolute;
  left: 0;
  top: var(--header-height);
  bottom: var(--footer-height, 0);
  width: var(--filters-width, 0);
  transition: 200ms;
}

.filters {
  overflow: auto;
  flex-grow: 1;
  flex-basis: 0;
  background: #fff;
  -webkit-overflow-scrolling: touch;
}

.datePicker {
  align-self: center;
  margin-top: 6px;
}

.header {
  height: 60px;
  background: var(--secondary-color);
  display: flex;
  flex-direction: row;
  position: relative;
}


.headerText {
  color: #fff;
  align-self: center;
  padding: 18px;
  display: none;
}

.togglePanel {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: var(--filters-closed-width);
  
  display: flex;
  align-items: center;
  justify-content: center;
  fill: rgba(160,160,160,1);
  cursor: pointer;
  transition: 200ms;
}

.togglePanel svg {
  height: 16px;
}

.togglePanel:hover {
  background: var(--primary-w90-color);
  --tone1: var(--primary-color);
  --tone2: var(--primary-color);
  --tone2-opacity: 0.2;
}

.opened .togglePanel svg {
  transform: rotate(180deg);
}

.opened .headerText {
  display: block;
}