.container {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0.5em;
  padding: 0.75em 1em;
}

.filedrop {
  background: #fff;
}
.filedropContent {
  border: 2px dashed #cfcfcf;
  border-radius: 12px;
  margin: 16px 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: var(--text);
  font-weight: 600;
  padding: 16px;
  text-align: center;
  min-height: 100px;
  background: #fff;
  transition: 200ms;
}

.filedrop.hovering .filedropContent {
  background: #dedae2;
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.files {
  padding: 8px 0;
}

.file {
  color: var(--text);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
}
.file:first-child {
  margin-top: 0;
}

.file svg {
  margin-right: 6px;
  height: 16px;
  width: auto;
}

.deleteIcon {
  margin-right: 0;
  margin-left: 6px;
  --icon-tone1: var(--text);
  --icon-tone1-opacity: 1;
  transition: 200ms;
  cursor: pointer;
}

.deleteIcon:hover {
  --icon-tone1: var(--error);
  --icon-tone1-opacity: 1;
}

.spacer {
  flex-grow: 1000;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}