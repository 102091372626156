.container {
  display: flex;
  flex-direction: column;
}

.header {
  padding: 12px 16px;
  font-size: 1.5em;
  font-weight: 600;
  flex-shrink: 0;
}

.scrollable {
  overflow: auto;
  flex-grow: 1;
}

.content {
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 32px 1fr min-content min-content;
  padding: 0 16px;
  grid-gap: 6px;
}

.email {
  font-size: 16px;
  color: var(--text);
  display: flex;
  align-items: center;
}

.icon {

}

.userType {
  color: #666;
  display: flex;
  align-items: center;
}

.addMember {
  display: contents;
}

.input {
  display: block;
}

.trash {
  height: 100%;
  width: 12px;
  cursor: pointer;
}

.trash svg {
  height: 100%;
  width: 100%;
}

.trash:hover {
  --icon-tone1: var(--error);
  --icon-tone2: var(--error);
}