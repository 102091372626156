.containerWrap {
  background: var(--primary-w90-color);
  min-height: 100vh;
}

.container {
  padding-top: 100px;
  padding-bottom: var(--footer-height);
}

.heading{
  font-weight: 600;
  letter-spacing: -0.02em;
}

.content {
  display: grid;
  grid-gap: 1em;
  padding: 1em;
  grid-auto-columns: minmax(0, 40em);
  justify-content: center;

  justify-items: left;
}

.addCreditCard {
  width: 100%;
}

.cards {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logout {
  border-radius: 50%;
}

.logoutButton, .logoutButton:link, .logoutButton:visited {
  font-size: 14px;
  color: #fff;
  padding: 12px 24px;
  background: var(--error);
  border-radius: 10000em;
  white-space: nowrap;
  outline: 0;
  border: none;
  cursor: pointer;
  transition: 200ms;

  &:hover{
    background: var(--error-text);
    box-shadow: 0px 1px 3px rgb(142, 142, 142);
    transform: translate(0, -2px);
    color: #fff;
  }

}
