.viz {
  background: rgba(207, 206, 236, 0.2);
  background: linear-gradient(135deg, rgba(209, 208, 237, 0.2), rgba(165, 163, 214, 0.2));
  position: relative;
}

.waves {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  max-height: 50%;
}

.logo {
  height: 2em;
  width: auto;
  margin-bottom: 1em;
  margin: 4em 4em 1em 4em;
  display: block;
}
.logo img {
  height: 100%;
  width: auto;
}

.vizContent {
  margin: 30% 4em 1em 4em;
}

.description { 
  font-size: 3vh;
  font-weight: 100;
  color: #494949;
  line-height: 1.3em;
}