.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary-w98-color, #F9F9F9);
  transition: 300ms;
  overflow: auto;
  color: #646464;
}

.top {
  flex-basis: 0;
  flex-grow: 3;
  flex-shrink: 0;
  margin: 0 auto;
  padding: 0;
  width: var(--top-width, 700px);
  max-width: 100%;
  margin: 0 auto;
}
.bottom {
  flex-basis: 0;
  flex-grow: 7;
  flex-shrink: 0;
  margin: 0 auto;
  padding: 0;
  width: var(--bottom-width, 405px);
  max-width: 100%;
}
.centered {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0 auto;
  padding: 0;
  width: var(--top-width, 405px);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.topContent {
  padding: 132px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.bottomContent {
  padding: 16px 16px calc(16px + var(--progess-bar-height, 0)) 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.centeredContent {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  width: 100%;
  /* height: 100%; */
}

.title {
  font-size: 40px;
  color: #2A313E;
  letter-spacing: -0.7px;
  line-height: 1.4em;
  text-align: center;
}

.title strong {
  font-weight: bold;
  color: var(--primary-color, currentColor);
}

.title space {
  position: relative;
  display: inline-block;
}
.title space:before {
  content: '(Fill in the blank)';
  font-size: 18.6px;
  color: var(--primary-w40-color);
  letter-spacing: 0;
  line-height: 33px;
  vertical-align: middle;
}
.title space:after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  background: var(--primary-color);
  border-radius: 50px;
  height: 4px;
  bottom: 6px;
}

.multiAnswer {
  font-size: 18px;
  color: #62708D;
  letter-spacing: 0;
  line-height: 1.5em;
  border: 1px solid #D3D4D5;
  padding: 12px 48px;
  border-radius: 1000em;
  margin: 8px 0;
  transition: 300ms;
  text-align: center;
  cursor: pointer;
  outline: 0;
  background: transparent;
  position: relative;
}

.multiAnswer:hover {
  transform: scale(1.1);
  border-color: var(--primary-color, #D3D4D5);
  color: var(--primary-color, #62708D);
}

.multiAnswer.selected {
  border-color: var(--primary-color, currentColor);
  background: var(--primary-color, currentColor);
  color: var(--text-color, '#fff');
  transform: scale(1.1);

  animation-name: blinker;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-duration: var(--animation-time, 500ms);
  animation-fill-mode: both;
  animation-direction: normal;
}
.multiAnswer:active {
  border-color: var(--primary-color, currentColor);
  background: var(--primary-color, currentColor);
  color: var(--text-color, '#fff');
}
.answerKeyWrap {
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #D3D4D5;
  background: #fff;
  transform: translate(calc(-100% + 38px), -50%);
}

.answerKey {
  padding-left: 0;
  display: none;
  visibility: hidden;
  opacity: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05em;
}
.answerShortcutKey {
  width: 22px;
  color: currentColor;
  font-size: 16px;
  /* font-weight: bold; */
}

.multiAnswer:hover .answerKey, .multiAnswer.selected .answerKey {
  padding-left: 7px;
  display: block;
  visibility: visible;
  opacity: 1;
  
}
.multiAnswer:hover .answerKeyWrap, .multiAnswer.selected .answerKeyWrap {
  border-color: var(--primary-color);
  background: var(--primary-w20-color);
  color: var(--text-color, '#fff');
}


.npsAnswer {
  letter-spacing: 0;
  margin: 10px;
  padding: 0;
  transition: 300ms;
  cursor: pointer;
  outline: 0;
  border: none;
  background: transparent;
  position: relative;
  flex-grow: 1;
  height: auto;
  --tone2: var(--primary-color);
  --tone1: #fff;
}
.npsAnswer svg {
  display: block;
  height: auto;
  width: 100%;
}
.npsAnswer .answerKeyWrap {
  top: unset;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  border: none;
}
.npsAnswer .answerKey {
  padding-left: 7px;
  display: block;
  visibility: visible;
  opacity: 1;
}

.npsAnswer.selected {
  transform: scale(1.1);

  animation-name: npsBlinker;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-duration: var(--animation-time, 500ms);
  animation-fill-mode: both;
  animation-direction: normal;
}
.npsAnswer.notSelected {
  transform: scale(0.7);
  opacity: 0.5;
}

.npsAnswer:hover {
  transform: scale(1.1);
  opacity: 1;
}

.numericInput {
  display: block;
  border: 1px solid var(--primary-color);
  font-size: 100px;
  border-radius: 12px;
  outline: 0;
  text-align: center;
  background: var(--primary-w95-color);
  color: var(--primary-color);
  font-weight: 500;
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
}

.textInput {
  display: block;
  border: 1px solid var(--primary-color);
  font-size: 16px;
  line-height: 1.5em;
  border-radius: 12px;
  outline: 0;
  background: var(--primary-w95-color);
  color: var(--primary-color);
  font-weight: 500;
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  resize: none;
}


.spacer {
  flex-grow: 10000;
}

.introLogo {
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  align-self: center;
}

.introLogo img {
  display: block;
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

.introText {
  font-size: 200;
  font-size: 38px;
  color: #646464;
  letter-spacing: 0;
  text-align: center;
  line-height: 46px;
}

.instroSubText {
  font-weight: 200;
  font-size: 26px;
  letter-spacing: -0.5px;
  text-align: center;
  line-height: 1.4em;
  margin-top: 12px;
}

.getStartedButton {
  display: block;
  align-self: center;
  margin-top: 30px;
}


.npsBoxes {
  display: flex;
  flex-direction: row;
}

.inputNextButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.badges {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px -10px;
  justify-content: center;
}

.badge {
  margin: 10px;
  filter: grayscale(100%);
  transition: 200ms;
  width: calc(25% - 20px);
}
.badge.unachieved {
  opacity: 0.3;
  transform: scale(0.8);
}
.badge.achieved {
  filter: none;
  opacity: 1;
  transform: scale(1.05);
}
.badge:hover {
  filter: none; 
}

.badgeExplainer {
  background: #fff;
  
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  border-radius: 10px;
  overflow: hidden;
}

.badgeExplainer.hidden {
  opacity: 0;
  height: 0;
  padding: 0;
}

.badgeExplainerHeader {
  font-weight: bold;
  font-size: 39px;
  letter-spacing: -1.41px;
  background: #efefef;
  padding: 8px 32px;
}

.badgeExplainerHeaderContent {
  padding: 32px;
  display: flex;
  flex-direction: row;
  margin: 0 -12px;
}

.badgeExplainerColumn {
  margin: 0 12px;
}

.badgeExplainerColumn h6 {
  margin: 0 0 4px 0;
}

@media screen and (max-width: 767px) {
  .title {
    font-size: 30px;
    line-height: 1.2em;
  }

  .title space:after {
    bottom: 2px;
    height: 2px;
  }

  .multiAnswer {
    font-size: 16px;
    padding: 10px 8px;
  }

  .footerButtons {
    display: flex;
    flex-direction: row;
    margin: 3px -5px;
    align-items: center;
  }
  .answerKeyWrap {
    display: none;
  }

  .bottom {
    width: 345px;
  }

  .bottomContent {
    padding: 16px 16px var(--bubble-progess-bar-height, 0) 16px;
  }

  .topContent {
    padding-top: 50px;
  }

  .centeredContent {
    padding: 50px 16px var(--bubble-progess-bar-height, 0) 16px;
  }

  .inputNextButton {
    display: none;
    margin: 0;
  }
}

@keyframes blinker {
  25%, 75% {
    border-color: var(--primary-w90-color, currentColor);
    background: var(--primary-w90-color, currentColor);
    color: var(--text-color, '#fff');
  }
  50%, 100% {
    border-color: var(--primary-color, currentColor);
    background: var(--primary-color, currentColor);
    color: var(--text-color, '#fff');
  }
}


@keyframes npsBlinker {
  25%, 75% {
    opacity: 0.6;
  }
  50%, 100% {
    opacity: 1;
  }
}