.container {
  background: #F9F9F9;
  margin: 0;
  padding: 0;
  height: 100vh;
  margin-left: var(--nav-width);
  transition: 200ms;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;
}

.search {
  flex-shrink: 0;
  margin: 12px 12px 4px 12px;
}

.contentWrap {
  flex-grow: 1;
  overflow: auto;
}

.content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: max-content;
  grid-gap: 0.7em;
  padding: 1em;
  flex-basis: 0;
  flex-grow: 1;
}

.loading {
  grid-column-start: 1;
  grid-column-end: -1;
}

.gridHeader {
  margin-top: 8em;
  grid-column-start: 1;
  grid-column-end: -1;
  font-weight: bold;
  color: #666;
  text-transform: capitalize;
}

.statusHeader {
  grid-column-start: 1;
  grid-column-end: -1;
  font-weight: bold;
  color: #666;
  display: flex;
}

.draftButton {
  font-size: 0.5em;
  margin: 0;
}

.showMoreRow {
  grid-column-start: 1;
  grid-column-end: -1;
}

.showMore {
  margin: 8px 16px;
  padding: 8px;
  border: 1px dashed var(--primary-color);
  border-radius: 6px;
  color: var(--primary-color);
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  transition: 200ms;
  cursor: pointer;
  display: block;
  text-decoration: none;
}
.showMore:hover {
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: #fff;
}

.icon {
  margin: 2px;
  opacity: 0.7;
}

.icon:hover {
  opacity: 1;
}

.loadingRow {
  grid-column-start: 1;
  grid-column-end: -1;
  height: 20px;
  padding: 4px 0;
}

.fakeIcon {
  background: var(--nav-text);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
  height: 32px;
  width: 32px;
  font-size: 14px;
  margin: 2px;
  border-radius: 6px;
}