.section {
  display: flex;
  flex-direction: column;
}

.seenSection {
  display: flex;
  flex-direction: column;
  transition: 200ms;
  cursor: pointer;
  padding: 0 20px;
}

.seenSection:hover {
  background: #fff;
}

.seenSectionTitle {
  color: #666;
}

.header {
  margin: 0 auto;
  padding: 0;
  font-size: 42px;
  line-height: 1.5em;
  font-weight: 600;
  transition: 200ms;
  overflow: hidden;
  color: #666;
}

.section .input {
  background: transparent;
  margin-top: 10px;
  border: none;
  color: #222;
  outline: 0;
  padding: 6px 16px;
  font-size: 50px;
  line-height: 1.5em;
  border-radius: 6px;
  transition: 200ms;
  text-align: center;
}


.submit {
  border-radius: 1000em;
  padding: 12px 24px;
  text-align: center;
  outline: 0;
  border: none;
  background: var(--primary-color);
  color: var(--text-color);
  display: block;
  align-self: center;
  font-size: 18px;
  margin-top: 12px;
  transition: 200ms;
}
.submit.clickable {
  cursor: pointer;
}

.submit.clickable:hover {
  transform: scale(1.1);
}





.brand {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 6px;
  overflow: hidden;
}

.apps {
  display: flex;
  flex-direction: column;
}

.app {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  --icon-size: 18px;
  min-height: 32px;
  transition: 200ms;
}
.app.clickable {
  cursor: pointer;
}
.app.clickable:hover {
  background: var(--primary-color);
  color: var(--text-color);
  --tone1: #fff;
  --tone2: #fff;
  --tone3: #fff;
  --tone4: #fff; 
}
.appSourceIcon {
  outline: 0;
  transition: 200ms;
  height: var(--icon-size);
  width: auto;
  display: block;
  margin: 0 16px 0 12px;
}

.appTitle {
  padding: 0 8px;
  flex-grow: 1;
}

.appGenres {
  margin: 0 4px;
}

.appStars {
  font-size: 12px;
  margin: 0 6px;
}

.appDeleteButton {
  background: none;
  border: none;
  margin: 0;
  display: block;
  padding: 6px;
  height: 32px;
  box-sizing: border-box;
  width: auto;
  transition: 200ms;
  cursor: pointer;
}
.appDeleteButton svg {
  height: 100%;
  width: auto;
  display: block;
}
.appDeleteButton:hover {
  background: #ff7575;
  --tone1: #fff;
  --tone2: #fff;
}




.addSourceRow {
  display: flex;
  flex-direction: row;
}
.addSourceButton {
  background: var(--primary-color);
  border-radius: 100em;
  color: var(--text-color);
  padding: 6px 12px;
  margin: 12px 10px 8px 10px;
  cursor: pointer;
  transition: 200ms;
  border: none;
  outline: 0;
}
.addSourceButton:hover {
  background: var(--primary-w30-color);
}

.addSourceContainer {

}


.addBrand {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;
  padding: 8px 12px;
  border: 1px dashed rgba(0,0,0,0.5);
  font-size: 22px;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
  margin: 10px 20px;
}
.addBrand:hover {
  background: #fff;
  color: var(--primary-color);
  border-color: var(--primary-color);
}





.sourcePicker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 6px;
}

.sourceRow {
  width: 100%;
  padding: 12px 4px 2px 4px;
  box-sizing: border-box;
  color: #666;
}

.source {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px;
  padding: 8px 12px;
  border: 1px solid #aeaeae;
  background: #fff;
  border-radius: 6px;
}

.source:hover {
  cursor: pointer;
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
  --tone1: #fff;
  --tone2: #fff;
  --tone3: #fff;
  --tone4: #fff;
  --tone5: #fff;
}

.sourceIcon {
  display: block;
  height: 22px;
  width: auto;
  margin-right: 8px;
}







.suggested {
  background: #f9f9e1;
}

.suggestedLoading {
  background: #f9f9e1;
  padding: 6px 12px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.suggestedError {
  background: #f9f9e1;
  padding: 6px 12px;
  font-size: 12px;
}

.suggestedSpinner {
  height: 8px;
  width: auto;
  flex-grow: 0;
}

.suggestedSpinner svg {
  height: 100%;
  width: auto;
  display: block;
}

.suggestions {
  display: flex;
  flex-direction: column;
}

.suggestedText {
  padding: 0 1em;
  background: #f9f9e1;
  font-size: 12px;
  color: var(--text);
  margin-top: 16px;
  align-self: flex-start;
  border-radius: 0 6px 0 0;
}




.addingBrand {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 6px;
  overflow: hidden;
}