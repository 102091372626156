.ratings {
  display: grid;
  grid-template-columns: 1fr minmax(0, 115px) 1fr;
  grid-auto-rows: 18px;
  align-items: center;
  grid-gap: 10px 0;
  margin-top: 11px;
}

.minRatings {
  display: flex;
  flex-direction: column;
}

.minRatingsRow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 1;
  padding: 6px 0;
  box-sizing: border-box;
  height: 44px;
  background: var(--faded-color);
  transition: 200ms;
  cursor: pointer;
}

.minRatingsRow:hover, .minRatingsRow.selected {
  background: var(--primary-w40-color);
}

.minRatingsRow:hover .background, .minRatingsRow.selected .background {
  background: var(--primary-color);
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  background: var(--color);
}

.star {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 1000em;
  background: #fff;
  padding: 2px 6px;
  position: relative;
  font-size: 12px;
  color: #666;
}
.star svg {
  height: 10px;
  display: block;
  width: auto;
  margin-left: 2px;
  fill: #666;
}

.ratingBar {
  align-self: stretch;
  display: flex;
  align-items: center;
  grid-column-start: 2;
  grid-column-end: span 1;
}
.ratingBar > * {
  flex-grow: 1;
}

.row {
  display: contents;
  color: #847C7C;
  font-size: 10px;
  cursor: pointer;
  fill: #D3D3D3;
}
.row.hoverable:hover, .row.selected {
  color: var(--primary-color);
  fill: var(--primary-color);
  --color: var(--primary-color) !important;
}

.rowName {
  text-align: right;
  padding-right: 15px;
  transition: 200ms;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  grid-column-start: 1;
  grid-column-end: span 1;
}
.rowName svg {
  margin-left: 2px;
  height: 1em;
  width: auto;
}
.rowName svg:first-child {
  margin-left: 0;
}
.rowName .rowIcon {
  margin-left: 0.75em;
  width: auto;
  height: 1em;
}
.rowCount {
  text-align: left;
  padding-left: 15px;
  width: 33px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  grid-column-start: 3;
  grid-column-end: span 1;
}

.tooltipRow {
  grid-column-start: 1;
  grid-column-end: -1;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.tooltipRowWords {
  margin-bottom: 6px;
}

.tooltipRowFooter {
  opacity: 0.8;
  color: #FBF9F6;
  font-size: 12px;
}

.datePicker {
  align-self: center;
  margin-top: 6px;
}

.delightedScore {
  font-size: 8px;
  background: var(--color);
  display: inline-flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 1000em;
  flex-direction: row;
  padding: 0.1875em 0.5em;
  font-weight: bold;
}

.delightedScore svg {
  width: auto;
  height: 0.625em;
  display: block;
  --tone1: #fff;
  stroke-width: 0.5em;
  flex-shrink: 0;
  flex-grow: 0;
}

.delightedScore .divider {
  display: block;
  margin: 0 0.3125em;
  border-radius: 1000em;
  width: 0.125em;
  height: 0.875em;
  background: rgba(255,255,255,0.3);
}