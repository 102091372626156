.quadrantContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 700px;
}
.quadrantChart {
  overflow: visible;
  position: relative;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.point {
  cursor: pointer;
  transition: 200ms;
}
.point:hover {
  r: 7;
  stroke-width: 2;
  stroke: #ffffff;
}
.point.selected {
  r: 7;
  stroke-width: 2;
  stroke: #ffffff;
}