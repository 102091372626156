.topics {
  display: flex;
  flex-direction: row;
  margin: 6px -3px -3px -3px;
  flex-wrap: wrap;
  padding: 0 3px;
}

.tooltipContent {
  font-size: 14px;
  line-height: 1.3em;
}