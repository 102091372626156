.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 15px 4px 15px;
  margin-top: -6px;
}

.linkIcon, .icon {
  fill: #B7B6B9;
  margin-left: 4px;
  transition: 200ms;
}

.icon {
  --tone1: #B7B6B9;
  --tone2: #B7B6B9;
  --tone3: #B7B6B9;
  --tone4: #B7B6B9;
}

.linkIcon {
  cursor: pointer;
}

.linkIcon:not(:hover) {
  --tone1: #B7B6B9;
  --tone2: #B7B6B9;
  --tone3: #B7B6B9;
  --tone4: #B7B6B9;
}

.linkIcon svg, .icon svg {
  height: 10px;
  width: auto;
}


.linkIcon:first-child, .icon:first-child {
  margin-left: 0;
}