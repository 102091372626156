.userTooltip {
  color: #6E6E6E;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-gap: 0.25em;
  align-items: center;
}

.userIcon {
  display: inline-block;
  vertical-align: bottom;
  border-radius: 1000em;
  overflow: hidden;

  height: 18px;
  width: 18px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 5px;
}
.userIcon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.accuracy {
  color: #6DC138;
  font-size: 14px;
  margin-left: 1em;
}

.userTooltip span {
  margin-left: 2px;
}

.tooltipContainer {
  padding: 4px;
  margin: 0;
}