.brands {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  opacity: 0.5;
  align-content: center;
  margin-left: 76px;
  margin-top: -6px;
}

.icon {
  margin: 0.25em 0.25em;
}

.text {
  margin: 0.25em 0.25em;
}

.text:after {
  content: ",";
}

.text:last-child:after {
  content: none;
}