.container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2fr 3fr;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content h1 {
  text-align: center;
  color: #454b5a;
}

.input {
  padding: 4px 16px;
  min-width: 400px;
}

.button, .button:link, .button:visited {
  border-radius: 10000em;
  background: var(--secondary-color);
  color: var(--primary-w90-color);
  transition: 200ms;
  text-decoration: none;
  font-weight: normal;
  background: linear-gradient(135deg, #382378, #630483);
  margin: 4px 16px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -1px;
  padding: 6px 22px;
  cursor: pointer;
}

.button:hover, .button:link:hover, .button:visited:hover {
  color: var(--primary-w90-color);
  box-shadow: 0 1px 3px rgba(99, 69, 146, 0.8);
  transform: translate(0, -2px);
}