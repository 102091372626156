.ratings {
  display: grid;
  grid-template-columns: 1fr minmax(0, 115px) 1fr;
  /* grid-auto-rows: 18px; */
  grid-auto-rows: min-content;
  align-items: center;
  /* grid-gap: 10px 0; */
  margin-top: 11px;
}

.adminRatings {
  grid-template-columns: min-content 1fr minmax(0, 115px) 1fr min-content ;
}

.ratingBar {
  align-self: stretch;
  display: flex;
  align-items: center;
  grid-column-start: 2;
  grid-column-end: span 1;
  margin: 5px 0;
  cursor: pointer;
}
.ratingBar > * {
  flex-grow: 1;
}

.adminRatings .ratingBar {
  grid-column-start: 3;
}

.ratingBar.selected, .rowName.selected, .rowCount.selected, .ratingBar.hovering, .rowName.hovering, .rowCount.hovering {
  color: var(--primary-color);
  --tone1: var(--primary-color);
  --color: var(--primary-color) !important;
}
.rowName.selected {
  text-decoration-line: underline;
  text-decoration-color: thistle;
}

.row.hoverable:hover {
  color: var(--primary-color);
  --tone1: var(--primary-color);
  --color: var(--primary-color) !important;
}


.aspectRow {
  display: contents;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  grid-column-start: 2;
  grid-column-end: span 1;
}

.adminRatings .aspectRow {
  grid-column-start: 3;
}

.aspectRowButton {
  padding: 3px;
  border-radius: 4px;
  background: #666;
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  background: var(--color);
  color: #fff;
  transition: 200ms;
  cursor: pointer;
}

.aspectRowButton:hover, .aspectRowButton.selected {
  background: var(--hover-color);
}

.aspectRowSpacer {
  flex-basis: 0;
  flex-grow: 1;
}

.rowName {
  text-align: right;
  padding-right: 15px;
  transition: 200ms;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  grid-column-start: 1;
  grid-column-end: span 1;
  height: 18px;
  cursor: pointer;
  color: #847C7C;
  font-size: 11px;
  cursor: pointer;
  --tone1: #D3D3D3;
  margin: 5px 0;
}
.rowName svg {
  margin-left: 2px;
  height: 1em;
  width: auto;
}
.rowName svg:first-child {
  margin-left: 0;
}
.rowName .rowIcon {
  margin-left: 0.75em;
  width: auto;
  height: 1em;
  display: block;
  flex-shrink: 0;
}
.adminRatings .rowName {
  grid-column-start: 2;
}

.rowCount {
  text-align: left;
  padding-left: 15px;
  padding-right: 6px;
  width: 33px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  grid-column-start: 3;
  grid-column-end: span 1;
  margin: 5px 0;
  cursor: pointer;

  color: #847C7C;
  font-size: 10px;
  cursor: pointer;
  --tone1: #D3D3D3;
}
.adminRatings .rowCount {
  grid-column-start: 4;
}

.newRowName {
  text-align: center;
  transition: 200ms;
  grid-column-start: 1;
  grid-column-end: -1;
  color: #847C7C;
  font-size: 10px;
  cursor: pointer;
  border: 1px dashed transparent;
  border-radius: 1000em;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: 200ms;
}

.newRowName:link, .newRowName:visited {
  color: #847C7C;
}
.newRowName:hover {
  border: 1px dashed #847C7C;
}

.tooltipRow {
  grid-column-start: 1;
  grid-column-end: -1;
  z-index: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.adminRatings .tooltipRow {
  grid-column-end: -2;
  grid-column-start: 2;
}

.tooltipRowWords {
  margin-bottom: 6px;
}

.tooltipRowFooter {
  opacity: 0.8;
  color: #FBF9F6;
  font-size: 12px;
}

.pin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  grid-column-start: 5;
  grid-column-end: span 1;
  cursor: pointer;
  transition: 200ms;
  fill: #eee;
  padding: 5px 0;
  box-sizing: border-box;
}

.pin svg {
  height: 1em;
  width: auto;
}

.pin:hover, .pinned {
  fill: var(--primary-color);
}

.dragger {
  padding-right: 2px;
  padding-left: 2px;
  grid-column-start: 1;
  grid-column-end: span 1;
  height: 100%;
  width: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  --tone1: #ddd;
  cursor: pointer;
  transition: 200ms;
}

.dragger svg {
  width: 100%;
  height: auto;
}

.dragger:hover {
  --tone1: var(--primary-color);
}
.droppable {
  grid-column-start: 1;
  grid-column-end: -1;
  height: 100%;
  pointer-events: none;
  transition: 200ms;
  position: relative;
  z-index: 10;
}
.droppable.draggingOver {
  user-select: none;
}

:global(.dragging) .droppable {
  z-index: 10;
  pointer-events: auto;
}

:global(.dragging) .ratingBar, :global(.dragging) .rowName, :global(.dragging) .rowCount {
  user-select: none;
}

.droppable .topBar, .droppable .bottomBar {
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background: var(--primary-color);
  border-radius: 4px;
  pointer-events: none;
  opacity: 0;
}

.droppable .topBar:after, .droppable .bottomBar:after {
  content: "";
  width: 4px;
  height: 4px;
  position: absolute;
  background: #fff;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
}

.droppable .topBar {
  top: -1px;
}
.droppable .topBar:after {
  top: -3px;
  left: -2px;
}

.droppable .bottomBar {
  bottom: -1px;
}
.droppable .bottomBar:after {
  top: -3px;
  left: -2px;
}

.droppable.draggingOver .topBar {
  opacity: 1;
}

.droppable.draggingUnder .bottomBar {
  opacity: 1;
}