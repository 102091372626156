.card {
  border-radius: 7px;
  padding: 15px 22px;
  margin: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 200ms;
  overflow: hidden;
  background: #FFFFFF;
  transition: 0.3s;
  flex-shrink: 0;
  position: relative;
}
.card.opened {
  box-shadow: 0 2px 14px 0 rgba(0,0,0,0.07);
  cursor: default;
  transition: all 200ms, box-shadow 500ms ease;
  padding: 17px 22px;
}
.card:not(.opened):hover {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.card.error {
  background: var(--error-input);
}
.card.error .cardIcon {
  background: var(--error);
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 13px 0 13px 51px;
}

.cardIcon {
  width: 40px;
  height: 40px;
  background: var(--primary-w90-color);
  font-size: 20px;
  color: #FFFFFF;
  fill: #FFFFFF;
  letter-spacing: -0.3px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  transition: 200ms;
  flex-shrink: 0;
}

.opened .cardIcon {
  background: var(--primary-color);
}

.input{
  display: inline-block;
  margin-right: 5px;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #444;
}

.cardHeaderText {
  letter-spacing: -0.01em;
  margin: 0 17px;
  color: #8c8c8c;
}

.opened .cardHeaderText {
  color: #444;
}


.conceptsSubtitle {
  margin: 0;
  line-height: 1.5em;
  color: #888;
  margin-top: -12px;
}

.conceptsSubtitle p {
  margin: 0;
  padding: 0;
}

.conceptsHeader {
  color: var(--text);
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
}

.conceptsHeader button {
  border-radius: 4px;
  border: 1px solid #ccc;
  color: var(--text);
  background: #fff;
  cursor: pointer;
  margin-left: 6px;
  padding: 2px 4px;
  font-size: 10px;
  outline: none;
}

.conceptsHeader button:hover {
  background: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.conceptsData {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -3px;
}



.brand {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 8px 0 8px 12px;
}
.brand:before {
  position: absolute;
  content: '';
  background: #cfcfcf;
  width: 1px;
  height: 100%;
  top: -10px;
  left: 28px;
  z-index: 0;
}
.lastBrand:before {
  position: absolute;
  content: '';
  background: #cfcfcf;
  width: 1px;
  top: -10px;
  height: 18px;
  left: 28px;
  z-index: 0;
}

.brandImage, .brandImageAdd {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  overflow: hidden;
  z-index: 1;
  flex-shrink: 0;
}

.brandImage img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.brandImageAdd {
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.brandContent {
  border-radius: 7px;
  padding: 15px 22px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 200ms;
  overflow: hidden;
  background: #FFFFFF;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.addBrandContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.appRow {
  border-radius: 4px;
  margin: 8px 0;
}

.searchAppRow {
  border-radius: 4px;
  margin: 3px -6px;
  padding: 6px;
}

.addSourcesWrap {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.addSourcesButton {
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 1000em;
  padding: 0.5em 1.3em .5em 0.8em;
  background: var(--secondary-w40-color);
  transition: 200ms;
  font-size: 11px;
}

.addSourcesButton:hover {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background: var(--secondary-w20-color);
}


.brandSources {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fill, 200px);

  &.premium {

  }
}

.brandSourcesHeader {
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 8px 8px 8px 8px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.brandSourcesHeader button {
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 1000em;
  padding: 4px 6px;
  background: #aea1bf;
  transition: 200ms;
  font-size: 11px;
  margin: 0 0 0 10px;
  outline: none;

  &:hover {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    background-color: #7d6c92;
  }
}

.brandSource {
  cursor: pointer;
  border-radius: 0.5em;
  padding: 0.5em;

  &:not(.premium):hover {
    background: var(--primary-color);
    --text: #fff;
  }
}

.createBrand {
  position: relative;
  display: flex;
  flex-direction: row;
  color: var(--text);
  align-items: center;
  transform: translate(0, 0);
  padding: 0.5em;
  margin: 0 -0.5em;
  border-radius: 4px;
  margin-top: 4px;

  &:hover {
    background: var(--primary-color);
    color: #fff;
    cursor: pointer;
    --tone1: #fff;
  }
}


.searchInput {
  margin: 6px -12px;
}

.suggestedLabel {
  font-size: 12px;
  color: var(--text);
  margin-top: 16px;
}
.suggestedLabel span {
  display: inline-block;
  padding: 0 1em;
  background: #f9f9e1;
  margin-top: 0.25em;
  margin-left: -8px;
}

.suggestLoading {
  background: #f9f9e1;
  height: 14px;
  margin: 0 -8px;
  padding: 4px;
  box-sizing: border-box;
}

.suggestLoading svg {
  height: 100%;
  width: auto;
}

.minimizeIcon {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 14px;
  height: 14px;
  fill: #666;
  cursor: pointer;
  transition: 300ms;
}
.minimizeIcon svg {
  width: 100%;
  height: 100%;
}
.minimizeIcon:hover {
  fill: var(--primary-color);
}

.tooltipUnderline {
  text-decoration: underline;
}

.adminButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -4px;
}

.button {
  border: none;
  outline: none;
  border-radius: 1000em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 10px;
  margin: 4px;
  background: transparent;
  --tone1: #444;
  --tone1-opacity: 1;
  --tone2: #888;
  --tone2-opacity: 1;
  color: #75748F;
  transition: 200ms;
  cursor: pointer;
}
.button.delete {
  color: var(--error-text);
  --tone1: var(--error-text);
  --tone2: var(--error);
}

.button:hover {
  background: var(--primary-color);
  color: #fff;
  --tone1: #fff;
  --tone2: #fff;
}
.button.delete:hover {
  background: var(--error-text);
}

.button svg {
  margin-right: 3px;
  width: 15px;
  height: auto;
}

