.footerBar {
  position: absolute;
  bottom: 0;
  left: 0; 
  right: 0;
  height: var(--progess-bar-height, 0);
  z-index: 100;
  background: var(--primary-w90-color);
  transition: all 200ms, opacity 400ms;
}
.footerBar div {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  background: var(--primary-color);
  
  transition: 200ms;
}

.hidden {
  opacity: 0;
}