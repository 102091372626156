.cell {
  font-size: 14px;
  color: #444;
  letter-spacing: -0.01em;
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: var(--nav-closed-width);
  transition: 200ms;
  padding: 6px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;
}
.cell.pilled {
  padding: 8px 14px;
}
.cell.deleting {
  animation-name: deleting;
  animation-duration: 300ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease; 
  animation-fill-mode: both;
  animation-direction: alternate;
}

a.cell {
  text-decoration: none;
  color: var(--primary-color);
}
a.cell:hover {
  text-decoration: underline;
}

.cell img {
  height: calc(var(--nav-closed-width) - 20px);
  width: auto;
  display: block;
}

.sticky {
  position: sticky;
  left: 0;
  z-index: 10;
  background: #F9F9F9;
  border-right: 1px solid #e1e1e1;
}

.cell.even {
  background-color: rgb(236, 235, 250);
}

.sticky.even {
  background-color: rgb(236, 235, 250);
}

@keyframes deleting {
  to {
    background: #ff7575;
    --tone1: #fff;
    --tone2: #fff;
    color: #fff;
  }
}