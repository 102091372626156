.section {
  padding: 1em;
  background: var(--primary-w90-color);
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 200ms;
}

.header {
  margin: 0 auto;
  padding: 0;
  font-size: 42px;
  line-height: 1.5em;
  font-weight: 600;
  transition: 200ms;
  overflow: hidden;
  color: #666;
}

.input {
  background: transparent;
  margin-top: 10px;
  border: none;
  color: #222;
  outline: 0;
  padding: 6px 16px;
  font-size: 50px;
  line-height: 1.5em;
  border-radius: 6px;
  transition: 200ms;
  text-align: center;
}

.submit {
  border-radius: 1000em;
  padding: 12px 24px;
  text-align: center;
  outline: 0;
  border: none;
  background: var(--primary-color);
  color: var(--text-color);
  display: block;
  align-self: center;
  font-size: 18px;
  margin-top: 12px;
  transition: 200ms;
}

.submit.clickable {
  cursor: pointer;
}

.submit.clickable:hover {
  transform: scale(1.1);
}

.seen {
  flex-grow: 0;
  background: transparent;
  cursor: pointer;
}

.seen:hover {
  background: var(--primary-w90-color);
}


.seen .header {
  height: 0;
  opacity: 0;
  padding: 0;
  margin: 0 auto;
}

.seen .input {
  background: transparent;
  font-size: 16px;
  padding: 0;
  margin: 0;
  text-align: left;
  pointer-events: none
}

.seen .submit {
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.seen:hover {
  background: #fff;
}