.brands {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fill, 200px);
}

.brand {
  cursor: pointer;
  border-radius: 0.5em;
  padding: 0.5em;
}

.brand:hover {
  background: var(--primary-color);
  --text: #fff;
}

.hiddenLabel {
  padding: 7px 0;
  color: var(--text);
  font-weight: 600;
  opacity: 0.5;
}

.nestedSubSection {
  padding: 0.25em 0 0.25em 0;
}

.nestedSubSection:before {
  margin-left: 0;
}

.row {
  position: relative;
  display: flex;
  flex-direction: row;
  color: var(--text);
  align-items: center;
  margin: 7px 0;
  transform: translate(0, 0);
}
.appRow {
  padding: 0.5em;
  margin: 0 -0.5em;
  border-radius: 4px;
}

.row.hoverable:hover {
  background: var(--primary-color);
  color: #fff;
  cursor: pointer;
  --tone1: #fff;
}

.index {
  padding-right: 0.25em;
  display: flex;
}

.stars {
  font-size: 8px;
  align-self: center;
}

.stars, .genre {
  padding-right: 0.75em;
  display: flex;
  align-items: center;
}
.appTitle {
  padding: 0 0.75em;
  display: flex;
  align-items: center;
}

.spacer {
  flex-grow: 1000;
}

.appIcon {
  min-height: 1em;
  min-width: 1em;
  --icon-size: 1em;
}

.inputBorder {
  padding: 0.5em 1em;
  margin-top: -0.5em;
  border-radius: 0.5em;
}

.appProvider {
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
  flex-shrink: 0;
}

.trashIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
  fill: var(--error);
  margin-left: 0.5em;
  --icon-tone1: #797979;
  --icon-tone2: #C7C7C7;
  --icon-tone1-opacity: 1;
  --icon-tone2-opacity: 1;
}
.trashIcon svg {
  height: 0.8em;
  width: auto;
}
.trashIcon svg:hover {
  --icon-tone1: var(--error);
  --icon-tone2: var(--error);
}

.suggested {
  background: #f9f9e1;
  border-color: #f9f9e1;
  padding: 0.5em;
  margin: 0 -0.5em;
  cursor: pointer;
}

.label {
  flex-grow: 1;
}

.suggestedLabel {
  font-size: 12px;
  color: var(--text);
}
.suggestedLabel span {
  display: inline-block;
  padding: 0 1em;
  background: #f9f9e1;
  margin-top: 0.25em;
  margin-left: -8px;
}

.suggestedSpiner {
  background: #f9f9e1;
  margin-left: -8px;
}

.suggestedSpiner svg {
  height: 32px;
  width: 60px;
}

.addSourcesWrap {
  display: flex;
  flex-direction: row;
}

.addSources {
  margin-top: -0.5em;
  color: #fff;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 1000em;
  padding: 0.5em 1.3em .5em 0.8em;
  background: #aea1bf;
  transition: 200ms;
  font-size: 11px;
}

.addSources:hover {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background-color: #7d6c92;
}

.pencil {
  height: 0.75em;
  width: auto;
  margin-right: 0.25em;
  fill: var(--text);
  cursor: pointer;
  transition: 300ms;
}

.pencil svg {
  height: 100%;
  width: auto;
}

.pencil:hover {
  fill: var(--primary-color);
}

.createBrand {
  position: relative;
  display: flex;
  flex-direction: row;
  color: var(--text);
  align-items: center;
  transform: translate(0, 0);
  padding: 0.5em;
  margin: 0 -0.5em;
  border-radius: 4px;
}

.createBrand:hover {
  background: var(--primary-color);
  color: #fff;
  cursor: pointer;
  --tone1: #fff;
}

.header {
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 8px 8px 8px 8px;
  font-size: 12px;
  font-weight: bold;
}