.container {
  background: #fff;
  height: var(--footer-height);
  flex-shrink: 0;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.05);
  display: flex;
  flex-direction: row;

  margin: 0;
  margin-left: var(--nav-width);
  transition: 200ms;
  box-sizing: border-box;
  justify-content: space-between;

  overflow: hidden;
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
}

.footerIcon {
  height: 1em;
  width: 1em;
  --icon-size: 1em;
  margin-right: 0.5em;
}

.footerButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 1px solid var(--background);
  padding: 1em;
  color: var(--text);
}
.footerButton:last-child {
  border-left: 1px solid var(--background);
  border-right: none;
}
.footerButton:hover {
  background: var(--primary-color);
  color: #fff;
  --tone1: #fff;
  --tone2: #fff;
}

.footerPrevChevron {
  fill: var(--text);
  margin-right: 0.5em;
  transform: rotate(-90deg);
}

.footerNextChevron {
  fill: var(--text);
  margin-left: 0.5em;
  transform: rotate(90deg);
}
.footerButton:hover .footerPrevChevron {
  fill: #fff;
}
.footerButton:hover .footerNextChevron {
  fill: #fff;
}