.icon {
  position: relative;
  background: var(--background, #6a6a6a);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
  box-shadow: 0 0 1px var(--box-shadow);
  transition: 300ms;
  border-radius: calc(var(--icon-size, 32px) * 0.17543103448);
  width: var(--icon-size, 32px);
  height: var(--icon-size, 32px);

  /*fixed subpixel rendering*/
  transform: translateZ(0);
}
.icon.rounded {
  border-radius: 50%;
}

.icon:link {
  cursor: pointer;
}

.img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: calc(var(--icon-size, 32px) * 0.17543103448);
  overflow: hidden;
}

.icon svg {
  width: 75%;
  height: 75%;
}

@supports (mix-blend-mode: color) {
  .grayscale .img:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #D8D8D8;
    z-index: 1;
    border-radius: 50%;
    mix-blend-mode: color;
  }
}

@supports not (mix-blend-mode: color) {
  .grayscale .img {
    filter: grayscale(100%);
  }
}