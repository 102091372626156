.container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 5fr 2fr;
  grid-template-rows: 1fr;
  background: var(--primary-w90-color);
  grid-gap: 1px 0;
  flex-grow: 1;
  min-height: 100%;
  padding: 1em 1em 1em 3em;
  box-sizing: border-box;

  margin-left: var(--nav-width);
  height: 100vh;
  overflow: auto;
}

.footerActionRow {
  color: #333;
  padding: 10px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 200ms;
}

.footerActionRow svg {
  height: 1em;
  width: auto;
}

.footerActionRow:hover {
  color: #fff;
  background: var(--primary-color);
  fill: #fff;
}

.fileUpload {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.9);
  z-index: -1;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  opacity: 0;
  transition: 600ms;
}

.fileUploadContent {
  border: 2px dashed #cfcfcf;
  border-radius: 12px;
  margin: 16px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: var(--text);
  font-weight: 600;
}

:global(.fileupload) * {
  pointer-events: none;
}

:global(.fileupload) .container {
  pointer-events: auto;
}

:global(.fileupload) .fileUpload {
  z-index: 10;
  opacity: 1;
  pointer-events: auto;
}

.toast {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toast svg {
  height: 16px;
  width: 32px;
  margin-right: 8px;
}

.submitButton {
  border-radius: 0.25em;
  padding: 0.5em 1em;
  background: var(--primary-color);
  color: var(--accent-text);
  transition: 300ms;
  text-decoration: none;
  border: none;
  outline: 0;
  display: inline-block;
}

.submitButton:disabled {
  cursor: not-allowed;
  background: #666;
}

.submitButton:not(:disabled):hover {
  background: var(--accent-hover);
  color: var(--primary-color);
  cursor: pointer;
  box-shadow: 0 0 5px var(--primary-color);
}


.delete {
  border-radius: 0.25em;
  padding: 0.5em 1em;
  background: var(--error);
  color: #fff;
  transition: 300ms;
  text-decoration: none;
  border: none;
  outline: 0;
  display: inline-block;
  margin-left: 6px;
}

.delete:disabled {
  cursor: not-allowed;
  background: #666;
}

.delete:not(:disabled):hover {
  background: var(--error-text);
  cursor: pointer;
  box-shadow: 0 0 5px var(--error);
}