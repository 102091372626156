.detail {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}

.scrollable {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.description {
  margin: 20px;
  font-size: 12px;
  line-height: 17px;
  color: #100C18;
}


.sectionHeader {
  font-size: 14px;
  font-weight: 600;
  color: #636169;
  line-height: 20px;
  padding: 0 6px;
  margin: 0 17px 0 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.sectionHeader:first-child {
  margin-top: 26px;
}
.sectionHeader span {
  margin-right: 6px;
}
.sectionHeader strong {
  margin-left: 6px;
}

.wordLabel, .wordLabel input {
  font-weight: 600;
  color: #636169;
  line-height: 20px;
  padding: 0;
}
.wordLabel {
  margin-right: 6px;
}

.section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px 17px 17px 17px;
}

.superString {
  color: red;
}

.spacer {
  flex-grow: 10000;
}

.deleteButton {
  margin: 6px 20px 6px 20px;
  padding: 8px;
  border-radius: 4px;
  background: #EF5A46;
  text-align: center;
  color: #FBDEDA;
  cursor: pointer;
  transition: 200ms;
}

.deleteButton:hover {
  background: #BF4838;
}

.button {
  margin: 6px 20px 6px 20px;
  padding: 8px;
  border-radius: 4px;
  background: rgb(135, 70, 239);
  text-align: center;
  color: rgb(233, 218, 251);
  cursor: pointer;
  transition: 200ms;
}

.button:hover {
  background: rgb(126, 56, 191);
}

.label {
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text);
  margin-right: 6px;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.5em;
}

.label:before {
  content: '';
  width: 6px;
  height: 6px;
  background: var(--color);
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 2px;
  margin-top: 2px;
  border: 1px solid rgba(0,0,0,0.2);
}

.clipboard {
  font-size: 10px;
  flex-grow: 1;
}

.phrase {
  margin: 1px 0;
  background: var(--color);
  border-bottom: 1px solid var(--color);
  border-radius: 4px;
  padding: 0.1em 0.3em 0.035em;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.6em;
}