.select {
  position: relative;
  display: flex;
  flex-shrink: 0;
  font-size: 14px;
  background-color: #fff;
  flex-direction: row;
  transition: 200ms;
}

.select select {
  border: none;
  background: transparent;
  color: var(--font);
  outline: none;
  padding: 0.5em 1.75em 0.5em 0.75em;
  font-weight: 500;
  font-size: 1em;
  display: block;
  -webkit-appearance: none;
  cursor: pointer;
  text-align: center;
  box-shadow: none;
  display: flex;
  align-items: center;
  font-family: var(--font);
}
.select.borderless:hover {
  background: var(--primary-w40-color);
  --text: var(--text-color);
}


.select:not(.borderless) select {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}

.select select:focus {
  outline: none;
}

.content {
  width: auto;
  position: relative;
  display: flex;
}

.brandIcon {
  align-self: center;
  margin-left: 6px;
  margin-right: -4px;
}

.content:after {
  content: " ";
  width: 0.5em;
  height: 0.5em;
  border-bottom: 1px solid var(--text);
  border-right: 1px solid var(--text);
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translate(-50%,-50%) rotate(45deg);
  pointer-events: none;
}

.option {
  position: relative;
}
