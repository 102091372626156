.bubbleFooterBar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--bubble-progess-bar-height, 0);
  background: var(--primary-w98-color, #F9F9F9);
  display: block;
  z-index: var(--z-index-hud);
  transition: 200ms;
}

.bubbleFooterBar.hidden {
  opacity: 0;
  transform: translate(0, 100%);
}

.bubbleFooterBarContent {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: auto;
  height: var(--bubble-progess-bar-height, 0);
  z-index: var(--z-index-hud);
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  transition: 200ms;
}

.bubbleFooterBubble {
  background: var(--primary-w90-color, #C4C4C4);
  width: var(--small-circle-size, 0);
  height: var(--small-circle-size, 0);
  border-radius: 100em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: transparent;
  font-weight: bold;
  margin: 0 var(--circle-spacer, 0);
  transition: 200ms;
}
.bubbleFooterBubble.selected {
  background: var(--primary-color, #C4C4C4);
  width: var(--circle-size, 0);
  height: var(--circle-size, 0);
  color: var(--text-color, '#fff');
}