.container {
  display: flex;
  flex-direction: row;
  background: #fff;
  min-height: 44px;
  justify-content: stretch;
  border-bottom: 1px solid #efefef;
}

.text {
  flex-grow: 1;
  padding: 4px 8px;
  align-self: center;
  font-weight: 600;
}

.container button {
  background: none;
  border: none;
  margin: 0;
  display: block;
  padding: 12px 6px;
  height: 44px;
  box-sizing: border-box;
  width: auto;
  transition: 200ms;
  cursor: pointer;
}
.container button svg {
  height: 100%;
  width: auto;
  display: block;
}
.container button:hover {
  background: var(--primary-color);
  --tone1: #fff;
  --tone2: #fff;
}

.container .deleteButton:hover {
  background: #ff7575;
  --tone1: #fff;
  --tone2: #fff;
}

.container .editButton {
  padding: 14px 6px;
}

.container input {
  border: none;
  background: var(--primary-w95-color);
  flex-grow: 1;
  flex-basis: 0;
  width: 0;
  padding: 4px 8px;
  outline: 0;
  transition: 200ms;
}
.container input:focus {
  background: var(--primary-w90-color);
}

.icon {
  align-self: center;
  margin-left: 6px;
  box-shadow: 0 0 1px rgba(0,0,0,0.8);
}