.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  width: var(--nav-width);
  transition: 200ms;
  overflow: hidden;
  z-index: 100;
  background: #f6f5f8;

  /* animation: slideFromLeft 200ms;
  animation-fill-mode: both; */
}

.container.opened {
  width: var(--nav-opened-width);
}


.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  overflow: auto;
}

.logoRow {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
  background: #fff;
  color: #100c18;
  transition: 200ms;
  height: 60px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: tiempos;
  --row-icon-size: var(--nav-closed-width);
}

.row {
  --row-icon-size: var(--nav-closed-width);
  display: flex;
  flex-direction: row;
  position: relative;
  height: var(--row-icon-size);
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
  color: #634591;
  transition: 200ms;
  overflow: hidden;
}
.row.clickable {
  cursor: pointer;
}
.row.active, a.row:hover, .row.clickable:hover {
  background: var(--primary-w90-color);
  --tone1: var(--primary-color);
  --tone2: var(--primary-color);
  --tone2-opacity: 0.2;
  color: var(--primary-color);
}
.row.active {
  font-weight: bold;
}

.row:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  background: var(--primary-color);
  transition: 200ms;
}

.row.active:after {
  width: 6px;
}

.rowIcon, .logo {
  flex-shrink: 0;
  height: var(--row-icon-size);
  width: var(--row-icon-size);
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: 200ms;
}
.rowIcon svg {
  display: block;
  height: 20px;
  width: auto;
}

.rowBrandIcon {
  opacity: 0.5;
}
.rowBrandIcon:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d8d8d8;
  z-index: 1;
  border-radius: 50%;
  mix-blend-mode: color;
  opacity: 1;
}

.opened .row > *:first-child {
  margin-left: 6px;
}

.row.active .rowBrandIcon {
  opacity: 1;
}
.row.active .rowBrandIcon:after {
  opacity: 0;
}

.subRows {
  position: relative;
  overflow: hidden;
  padding: 0;
  transition: 200ms;
  height: 0;
  flex-shrink: 0;
}
.opened .subRows {
  padding: 0 calc(var(--nav-closed-width)/2);
}

.subRows:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  background: var(--primary-color);
  transition: 200ms;
}

.row.active + .subRows {
  background: var(--primary-w90-color);
  height: auto;
}
.row.active + .subRows:after {
  width: 6px;
}


.subRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
  transition: 200ms;
  overflow: hidden;

  color: #847C7C;
  font-size: 14px;
  /* height: 17px; */
  padding: 4px 0px 4px 6px;
  --tone1: #847C7C;
  --tone2: #847C7C;
  --tone3: #847C7C;
  --tone4: #847C7C;
  justify-content: center;
}

.subRow .rowText {
  width: 0;
  overflow: hidden;
  display: none;
}

.opened .subRow {
  justify-content: flex-start;
}

.opened .subRow .rowText {
  width: auto;
  display: block;
}

.subRow.active, .subRow:hover {
  color: var(--primary-color) !important;
  --tone1: var(--primary-color);
  --tone2: var(--primary-color);
  --tone3: var(--primary-color);
  --tone4: var(--primary-color);
}
.subRow.active {
  font-weight: bold;
}

.subRow:last-child {
  margin-bottom: 15px;
}

.subRow svg {
  height: 12px;
  width: auto;
  margin-right: 4px;
  flex-shrink: 0;
  display: block;
}

.subRow .rowTextAsSvg {
  margin-right: 4px;
  flex-shrink: 0;
  display: block;
}

.logo svg {
  display: block;
  height: 60%;
  width: auto;
}

.rowText {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.rowText:last-child {
  padding-right: 8px;
}

.opened .rowText {
  opacity: 1;
  width: auto;
}


.togglePanel {
  position: absolute;
  bottom: 0;
  right: 0;
  width: var(--nav-closed-width);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: rgba(160,160,160,1);
  cursor: pointer;
  transition: 200ms;
}
.togglePanel:after {
  content: '';
  height: 1px;
  left: 4px;
  right: 4px;
  top: 0;
  background: linear-gradient(90deg, rgba(208, 208, 208, 0), rgb(208, 208, 208), rgba(208, 208, 208, 0));
  position: absolute;
  transition: 200ms;
}
.togglePanel svg {
  height: 16px;
}

.togglePanel:hover {
  background: var(--primary-w90-color);
  --tone1: var(--primary-color);
  --tone2: var(--primary-color);
  --tone2-opacity: 0.2;
}

.opened .togglePanel {
  position: absolute;
  bottom: 0;
  right: 0;
  height: var(--nav-closed-width);
  width: 32px;
}
.opened .togglePanel:after {
  opacity: 0;
}

.opened .togglePanel svg {
  transform: rotate(180deg);
}

.footer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  padding-bottom: 32px;
  transition: 200ms;
  background: #f6f5f8;
}

.footer:before {
  content: '';
  height: 1px;
  top: 0;
  left: 4px;
  right: 4px;
  background: linear-gradient(90deg, rgba(208, 208, 208, 0), rgb(208, 208, 208), rgba(208, 208, 208, 0));
  position: absolute;
  transition: 200ms;
  opacity: 0;
}

.opened .footer {
  padding-bottom: 0;
}
.opened .footer:last-child {
  padding-right: 32px;
}

.opened .footer:before {
  opacity: 1;
}

.feedback {
  z-index: 1;
}

.opened .feedback {
  --row-icon-size: calc(var(--nav-closed-width) * 0.65);
  background: var(--primary-w20-color);
  color: var(--text-color);
  margin: 0 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  --tone2: var(--text-color);
  --tone2-opacity: 1;
  transform: translate(0, 2px);
}

.opened .feedback:hover {
  transform: translate(0, 0);
  --tone2: var(--text-color);
  --tone2-opacity: 1;
  background: var(--primary-color);
  color: var(--text-color);
}

.feedback + .footer {
  z-index: 2;
}

/* @keyframes slideFromLeft {
  from {
    transform: translate(-100%, 0);
  }
} */