.textarea {
  outline: none;
  border: none;
  resize: none;
  padding: 0.375em 0.5em;
  border-radius: var(--border-radius, 0.25em);
  background: var(--accent-input);
  transition: all 200ms, height 0ms;
  border: 3px solid transparent;
}

.textarea:focus {
  border-color: var(--focus-border-color, transparent);
}