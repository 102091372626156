.section {
  padding: 1em;
  background: var(--primary-w90-color);
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 200ms;
}


.header {
  margin: 0 auto;
  padding: 0;
  font-size: 42px;
  line-height: 1.5em;
  font-weight: 600;
  transition: 200ms;
  overflow: hidden;
  color: #666;
}


.subheader {
  margin: 0 auto;
  padding: 0;
  font-size: 28px;
  line-height: 1.5em;
  font-weight: 500;
  transition: 200ms;
  overflow: hidden;
  color: #666;
}


.conceptsSubtitle {
  margin: 0;
  line-height: 1.5em;
  color: #888;
  margin-top: -12px;
}

.conceptsSubtitle p {
  margin: 0;
  padding: 0;
}

.conceptsHeader {
  color: var(--text);
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
}

.conceptsHeader button {
  border-radius: 4px;
  border: 1px solid #ccc;
  color: var(--text);
  background: #fff;
  cursor: pointer;
  margin-left: 6px;
  padding: 2px 4px;
  font-size: 10px;
  outline: none;
}

.conceptsHeader button:hover {
  background: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.conceptsData {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -3px;
}


.tag {
  margin: 6px;
  border-radius: 100em;
  background: #fff;
  cursor: pointer;
  transition: 200ms;
  padding: 6px 8px;
}

.tag.selected, .tag:hover {
  background: var(--primary-color);
  color: var(--text-color);
}