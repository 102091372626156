.container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  grid-auto-rows: 1fr;
  grid-auto-rows: min-content;
  flex-shrink: 0;
  align-content: start;
  background: #ffffff;
  overflow: hidden;
  padding: 21px 33px 51px;
}

.name {
  font-size: 12px;
  color: #808080;
  grid-column-start: 1;
  grid-column-end: -1;
  margin-top: 1.2em;
  line-height: 1.4em;
  margin-bottom: 4px;
  margin-left: 8px;
  font-weight: 600;
}
.name:first-child {
  margin-top: 0;
}

.row {
  position: relative;
  grid-column-start: 1;
  grid-column-end: -1;
  height: 20px;
  background: #f7f7f7;
  border-radius: 10000em;
  transition: all 500ms;
}

.circle {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background: var(--color);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  transition: 200ms;
  pointer-events: none;
}

.icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 1;
  transition: 200ms;
  
  --icon-size: 10px;
  opacity: 0;
  visibility: hidden;
}

.row:hover {
  z-index: 1;
  background-color: hsla(262, 37%, 31%, 0.12);
}

.row:hover .icon {
  z-index: 10;
  /* transform: translate(-50%, var(--hover-offset)); */
  opacity: 1;
  visibility: visible;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.icon.visible {
  --icon-size: 24px;
  opacity: 1;
  visibility: visible;
}

.rowTooltipContent {
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 2px 12px;
}

.tooltipRow {
  display: contents;
}

.circleTooltipRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.circleTooltipRowIcon {
  --icon-size: 26px;
  margin-right: 6px;
}

.circleTooltipRowTitle {
  margin-right: 6px;
  font-size: 16px;
}

.circleTooltipRowScore {
  font-size: 16px;
}