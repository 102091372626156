.container {
  flex-grow: 1;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  padding: 0 16px;
}

.bubbles {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.traces {
  height: 100%;
  width: 100%;
}

.chart {
  flex-grow: 1;
  flex-shrink: 1;
}

.header {
  text-transform: capitalize;
  margin: 0;
}

.text {
  line-height: 1.5em;
  font-size: 18px;
  margin: 0.5em 0;
}

.text strong {
  font-weight: 600;
}
.ul {
  line-height: 1.5em;
  font-size: 18px;
  margin-top: 0.5em;

  display: grid;
  flex-direction: column;
  grid-template-columns: min-content max-content;
  grid-gap: 0 10px;
}

.ul .row {
  display: contents;
}

.percent{
  color: var(--secondary-color);
  font-weight: 600;
  text-align: right;
  margin-left: 40px;
  position: relative;
}

.percent:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -14px;
  transform: translate(-100%, -50%);
  border: 1px solid var(--text);
}


.percent em {
  font-style: normal;
  font-weight: 100;
  color: #606060;
  font-size: 12px;
  margin-left: 2px;
  vertical-align: super;
  line-height: 1em;
}

.positive {
  background: #8cc78c;
  color: #fff;
  padding: .1em 0.3em;
  border-radius: 4px;
}

.negative {
  background: #e45757;
  color: #fff;
  padding: .1em 0.3em;
  border-radius: 4px;
}

.icon {
  height: 0.8em;
  width: auto;
  display: inline-block;
  --tone1: var(--secondary-color);
  margin-right: 0.25em;
}

.bubbleChart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bubbleChart svg {
  align-self: center;
}

.bubbleChartTrace {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-auto-rows: min-content;
  grid-gap: 0px 12px;
  color: var(--text);
  line-height: 1.6em;
  flex-shrink: 0;
  flex-grow: 1;
}

.bubbleChart.stacked .bubbleChartTrace {
  margin: 0 12px;
}

.bubbleChartTraceHeader {
  grid-column-start: 1;
  grid-column-end: -1;
  font-weight: 600;
  font-size: 11px;
  text-decoration-color: lightgray;
  text-decoration-line: underline;
}

.chartTraceRow {
  display: contents;
}

.legendText {
  font-size: 12px;
  font-weight: 100;
  line-height: 1.6em;
  color: var(--text);
  padding: 2px 0;
}

.legendValue {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  line-height: 1.6em;
  padding: 2px 0;
}

.bar {
  position: relative;
  height: 2px;
  grid-column-start: 1;
  grid-column-end: -1;
  background: var(--color);
  border-radius: 1000em;
  overflow: hidden;
  margin-bottom: 6px;
  transition: 200ms;
}

.posBar {
  position: absolute;
  background: var(--color);
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc( var(--width) + 4px);
  border: 2px solid #fff;
  transition: 200ms;
}

.negBar {
  position: absolute;
  background: var(--color);
  top: -2px;
  right: -2px;
  height: calc(100% + 4px);
  width: calc( var(--width) + 4px);
  border: 2px solid #fff;
  transition: 200ms;
}

.circleTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.circleTextHeader {
  font-family: var(--font);
  font-size: 15px;
  font-weight: bold;
  color: var(--primary-color);
  text-align: center;
}
.circleTextHeader > div {
  line-height: 1.2em;
}

.circleTextSubHeader {
  font-family: var(--font);
  font-size: 15px;
  font-weight: 100;
  color: var(--primary-color);
  text-align: center;
}

.circleTextSubHeader > div {
  line-height: 1.2em;
}

.circle {
  fill: var(--primary-w90-color);
  stroke: #fff;
  stroke-width: 3;
}


@media only screen and (max-width: 610px) {
  .bubbleChart {
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }
}