.container {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0.5em;
  padding: 0.75em 1em;
}

.input {
  margin-bottom: 10px;
}

.spacer {
  flex-grow: 1000;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}