.container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  background: var(--primary-w90-color);
  flex-basis: 0;
  flex-grow: 1;
}