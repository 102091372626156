.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-auto-rows: max-content;
  grid-gap: 0.7em;
  margin-top: 0.5em;
  padding: 1em;
}

.loading {
  grid-column-start: 1;
  grid-column-end: -1;
}

.gridHeader {
  margin-top: 8em;
  grid-column-start: 1;
  grid-column-end: -1;
  font-weight: bold;
  color: #666;
  text-transform: capitalize;
}

.gridHeader:first-child {
  margin-top: 1em;
}

.user {
  border: 1px solid transparent;
  box-shadow: 0 0 4px var(--box-shadow);
  border-radius: 4px;
  padding: 0.7em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spacer {
  flex-grow: 1000;
}

.admin {
  font-size: 0.75em;
  margin: 0 0.5em;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  
  --accent: rgb(179, 64, 64);
  --accent-hover: rgba(179, 64, 64, 0.15);
}

.index {
  padding-right: 0.5em;
  color: #999;
}