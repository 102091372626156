.containerWrap {
  background: var(--primary-w90-color);
  min-height: 100vh;
}

.message {
  max-width: 30em;
  margin: 2em auto;
  color: var(--text);
  font-size: 1.25em;
  line-height: 1.6em;
  text-align: center;
}

.router {
  margin: 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  transition: 200ms;
  height: 100vh;
  box-sizing: border-box;
  padding-top: var(--header-height);
  padding-bottom: var(--footer-height);
  margin-left: var(--nav-width);
  position: relative;
}

.publicUrl {
  color: var(--text);
  font-size: 10px;
}

.deleteButton {
  height: 1.5em;
  width: auto;
  cursor: pointer;
  --icon-tone1-opacity: 1;
  --icon-tone1: var(--error);
  --icon-tone2-opacity: 0.2;
  --icon-tone2: var(--error);
}

.deleteButton:hover {
  --icon-tone2-opacity: 0.6;
}

.datePicker {
  align-self: center;
}

.demoFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.demoFooterButton, .demoFooterButton:link, .demoFooterButton:visited {
  font-size: 14px;
  color: #fff;
  padding: 6px 12px;
  background: #47326C;
  border-radius: 10000em;
  outline: 0;
  border: none;
  cursor: pointer;
  transition: 200ms;
}

.demoFooterButton:hover, .demoFooterButton:link:hover, .demoFooterButton:visited:hover {
  box-shadow: 1px 2px 1px 0 rgb(45, 30, 70);
  transform: translate(0, -2px);
  color: #fff;
}


.usersPopup {
  background: #fff;
  border-radius: 6px;
  min-height: 50%;
  max-height: 90%;
  min-width: 50%;
  box-shadow: 0 2px 4px rgba(20,20,20,0.5);
}

.dateContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.jobs {
  display: block;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.job {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.5em;
  transition: 200ms;
}

.warningIconLeft {
  position: absolute;
  top: 50%;
  left: 12px;
  height: 60%;
  --tone1: #fff;
  transform: translate(0, -50%);
}

.warningIconRight {
  position: absolute;
  top: 50%;
  right: 12px;
  height: 60%;
  --tone1: #fff;
  transform: translate(0, -50%);
}

.header {
  margin: 0;
  margin-left: var(--nav-width);
  transition: 200ms;
  box-sizing: border-box;
  height: var(--header-height);
  overflow: hidden;
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  background: var(--error-text);
  color: #fff;
}

.footer {
  background: #fff;
  height: var(--footer-height);
  flex-shrink: 0;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.05);
  display: flex;
  flex-direction: row;

  margin: 0;
  margin-left: var(--nav-width);
  transition: 200ms;
  box-sizing: border-box;

  overflow: hidden;
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
}