.quadrants {
  margin: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  background: #E3DCDC;
  grid-gap: 1px;
}

.quadrant {
  background: #fff;
  font-size: 11px;
  line-height: 15px;
  color: #C4C1C1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
}

.scoreBox {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: var(--color, #ccc);
  color: #fff;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 11px;
}

.arrow {
  width: 40px;
  height: 40px;
  margin: 11px;
}

.spacer {
  flex-grow: 1000;
}

.rank {
  font-size: 13px;
  color: #D9D7D7;
  vertical-align: top;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 4px;
  padding: 14px 0;
}
.rank > * {
  display: block;
}
.rank strong {
  font-size: 22px;
  color: #847C7C;
  margin: 0;
  margin-right: 4px;
  transform: translateY(4px);
}