.container {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.polarContent {
  position: relative;
  width: auto;
  height: auto;
}

.containerChart {
  position: relative;
  overflow: visible;
  z-index: 1;
}

.concepts {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}

.labels {
  display: grid;
  grid-gap: 0.5em;
  grid-auto-flow: column;
  font-size: 14px;
  align-self: center;
  margin-bottom: 1em;
}

.label {
  position: absolute;
  top: 0;
  left: 0;
  color: #455a64;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  line-height: 1.2em;
}

.conceptIcon {
  height: 0.75em;
  width: auto;
  --tone1: var(--primary-w40-color);
  margin-right: 0.25em;
}

.appPicker {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0.5em;
  overflow: hidden;
  display: flex;
  z-index: 2;
  flex-direction: column;
  max-height: 50vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid var(--border-color, #efefef);
}

.appPickerRow {
  padding: 0.5em 1em;
  border-top: 1px solid var(--border-color, #efefef);
  background: var(--background-color, #fff);

  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  transition: 300ms;
}

.appPickerRow:first-child {
  border-top: none;
}

.appLabelIcon {
  --icon-size: 1.5em;
}

.appLabelText {
  color: var(--color, var(--text));
}

.appLabelIcon + .appLabelText {
  margin-left: 0.5em;
}

.appProvider {
  position: absolute;
  top: calc(50% + 1.5em/2);
  left: 0.75em;
  margin: 0;
  transform: translate(-25%, -75%);
  --tone1: var(--text);
  --tone2: var(--text);
}

.appPickerRow:hover {
  background: var(--border-color);
}

.spacer {
  flex-grow: 10000;
}

.point {
  stroke-width: 2;
  stroke: #fff;
  transition: '200ms';
  fill: var(--color);
}

.brands {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 600px;
  margin: 16px;
}

.brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  --icon-size: 32px;
  margin: 4px;
  position: relative;
  opacity: 0.5;
  cursor: pointer;
  padding: 2px;
  border: 2px solid transparent;
  transition: 200ms;
  border-radius: 10000em;
}
.brand.selected {
  opacity: 1;
  background: var(--background-color);
  border-color: var(--border-color);
}

.brandLabel {
  margin-left: 6px;
  color: var(--color);
  margin-right: 4px;
}


.circleTooltipContainer {
  padding: 0;
}

.circleTooltip {
  color: #333;
  display: flex;
  flex-direction: row;
  border-left: 7px solid var(--color, #999);
  padding: 6px 8px;
}

.tooltipText {
  font-size: 12px;
  max-width: 180px;
  white-space: normal;
  margin-left: 7px;
  color: #837E7E;
}
.tooltipTextHeader {
  font-weight: bold;
  margin-bottom: 7px;
}

.tooltipText p {
  margin: 0;
}

.positive {
  color: #68AA41;
}
.negative {
  color: #AA4141;
}

.axis, .labelRect {
  fill: none;
  stroke-width: 1px;
  stroke: #D9D9D9;
}
.labelRect {
  fill: #fff;
}

.axisText {
  fill: '#666';
  font-size: 10px;
}

.path {
  fill-opacity: 0.2;
  stroke-width: 2;
  transition: 200ms;
  pointer-events: none;
  fill: var(--color);
  stroke: var(--color);
}