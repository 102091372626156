.container {
  font-family: sans-serif;
}

.shortStarsContainer {
  background: var(--star-rating);
  border-radius: 10000px;
  padding: 0.25em 0.5em;
  color: var(--star-color, #5B5B5B);
}
.shortStarsContainer span {
  padding-left: 0.25em;
}

.stars {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-auto-rows: max-content;
  grid-gap: 2px;
  transition: 300ms;
  align-items: baseline;
}

.star {
  color: #FCDDAB;
  position: relative;
  overflow: hidden;
}

.activeStar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #FFB135;
}