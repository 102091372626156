.container {
  color: #333;
  font-size: 12px;
  padding: 4px 8px;
}

.header {
  font-weight: bold;
  color: #5B5B5B;
  padding: 0.25em 0;
  font-size: 16px;
  white-space: nowrap;
}

.text {
  color: #929292;
  white-space: normal;
  overflow: hidden;
  padding-bottom: 0.25em;
  font-size: 12px;
  white-space: nowrap;
}