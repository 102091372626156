.detailHeader {
  height: 100%;
  background: var(--color, #444);
  flex-shrink: 0;
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translate(0, -50%);
  transition: 200ms;
  background: #fff;
  box-shadow: 0 0 5px #333;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #333;
  border-radius: 50%;
}
.icon svg {
  height: 40%;
  width: 40%;
}

.title {
  position: absolute;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  top: 50%;
  overflow: hidden;
  left: 16px;
  right: 70px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: 200ms;
  transform: translate(0, -50%);
  background: none;
  border: none;
  outline: 0;
}
.title input {
  background: none;
  border: 1px solid transparent;
  outline: 0;
  display: block;
  min-width: 0;
  flex-grow: 1;
  padding: 6px;
  margin: -6px;
  border-radius: 4px;
  transition: 200ms;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.title input:focus {
  background: var(--accent-input);
  background: var(--primary-w90-color);
  color: var(--text);
}
.icon + .title {
  left: 61px;
  transform: translate(0, -50%);
}

.opened .icon {
  width: 54px;
  height: 54px;
  transform: translate(0, calc(-50% + 12px));  
}

.opened .title {
  left: 16px;
  transform: translate(0, -50%);
}

.opened .icon + .title {
  left: 90px;
  transform: translate(0, calc(-50% + 7px));
}

.chevron {
  position: absolute;
  height: 19px;
  width: 19px;
  fill: #fff;
  right: 22px;
  top: 50%;
  transform: translate(0, -50%);
  transition: 200ms;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chevron svg {
  display: block;
}
.opened .chevron {
  transform: translate(0, -50%) rotate(180deg);
}

.chevron:hover {
  transform: translate(0, -50%) rotate(90deg);
}

.close {
  position: absolute;
  height: 19px;
  width: 19px;
  fill: #fff;
  right: 50px;
  top: 50%;
  transform: translate(0, -50%);
  transition: 200ms;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.close svg {
  display: block;
}

.close:hover {
  transform: translate(0, -50%) scale(1.2);
}