.container {
  flex-shrink: 0;
  display: flex;
  position: relative;
  flex-direction: row;
}

.input {
  flex-grow: 1;
  border-radius: 0.375em 0 0 0.375em;
  border: 1px solid #E3DCDC;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
  background: #fff;
  outline: none;
  padding: 0.5em;
  font-size: 12px;
  font-weight: 100;
  color: var(--text);
  transition: 200ms;
  min-width: 0;
}
.input:focus {
  background: var(--accent-input);
  border-color: #a8a7d0;
}

.saveToClipboard {
  display: flex;
  cursor: pointer;
  padding: 0.375em 0.75em;
  border: 1px solid #E3DCDC;
  align-items: center;
  border-radius: 0 0.375em 0.375em 0;
  border-left: none;
  background: #eff3f6;
  fill: var(--text);
  transition: 200ms;
}
.saveToClipboard:hover {
  fill: #fff;
  background: var(--primary-color);
}

.saveToClipboard svg {
  height: 1em;
  width: auto;
}

.deleteIcon:hover {
  fill: var(--primary-color);
}

.hasValue .deleteIcon {
  opacity: 1;
  visibility: visible;
}