.cell, .providerCell, .centeredCell, .nan {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0.5em 1.1em;
  color: #5B5B5B;
}

.providerCell {
  padding-right: 0;
  padding-left: 0.75em;
  justify-content: center;
}
.centeredCell {
  justify-content: center;
}
.nan {
  color: #EAEAEA;
  justify-content: center;
}


.appIcon {
  margin-right: 0.5em;
  flex-shrink: 0;
}

.appTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 12em;
}

.providerCell svg {
  height: 1em;
  width: 1em;
  fill: var(--text);
  --tone1: var(--text);
}

.shortStars {
  font-size: 0.65em;
}

.text {
  color: var(--text);
}

.demoText {
  background: var(--text);
  border-radius: 0.25em;
}

.circle {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #fff;
  border: 2px solid rgba(0, 0, 0, 0.07);
  flex-shrink: 0;
  background: var(--circle);
}
.circle svg {
  height: .6em;
  width: .6em;
}

.arrow {
  display: block;
  width: 24px;
  height: 24px;
}
.arrow svg {
  width: 100%;
  height: 100%;
}

.score {
  color: rgba(0, 0, 0, 0.56);
  border-radius: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: var(--score-color);
  height: 32px;
  min-width: 32px;
  box-sizing: border-box;
}

.headerCell {
  padding: 0.5em 0;
  background: #fff;
  border-bottom: 1px solid var(--background);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  transition: 300ms;
  font-size: 14px;
}

.headerCellContent {
  position: relative;
  white-space: nowrap;
  border-radius: 100000px;
  padding: 0.5em 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 300ms;
  color: #5B5B5B;
}
.headerIcon {
  height: 1em;
  width: auto;
  margin-right: 8px;
  transition: 300ms;
  fill: #5B5B5B;
  --tone1: #5B5B5B;
}
.headerText {
  transition: 300ms;
}
.headerChevron {
  width: 10px;
  height: auto;
  transition: 300ms;
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0;
  margin: 0;
  fill: #5B5B5B;
}
.headerCell:hover .headerCellContent, .headerCell.asc .headerCellContent, .headerCell.desc .headerCellContent {
  background: var(--primary-w95-color);
  color: var(--primary-color);
}
.headerCell:hover .headerChevron, .headerCell.asc .headerChevron, .headerCell.desc .headerChevron {
  opacity: 1;
  fill: var(--primary-color);
}
.headerCell:hover .headerText, .headerCell.asc .headerText, .headerCell.desc .headerText {
  transform: translate(-8px, 0);
}
.headerCell:hover .headerIcon, .headerCell.asc .headerIcon, .headerCell.desc .headerIcon {
  --tone1: var(--primary-color);
  transform: translate(-8px, 0);
}

.headerCell.asc .headerChevron {
  transform: translate(0, -50%) rotate(-90deg);
}

.headerCell.desc .headerChevron {
  transform: translate(0, -50%) rotate(90deg);
}


.headerCellContent {
  flex-direction: column;
  align-items: center;
}
.headerCell .headerIcon {
  margin-right: 0;
}
.headerCell .headerIcon + .headerText {
  margin-top: 2px;
  font-size: 8px;
}

.headerCell:hover .headerText, .headerCell.asc .headerText, .headerCell.desc .headerText {
  transform: translate(-4px, 0);
}
.headerCell:hover .headerIcon, .headerCell.asc .headerIcon, .headerCell.desc .headerIcon {
  transform: translate(-4px, 0);
}



.tooltipSentimentScore {
  font-weight: 600;
  color: #333;
  font-size: 12px;
  white-space: nowrap;
}

.tooltipSentimentText {
  margin-top: 0.25em;
  color: #333;
  font-size: 12px;
  white-space: nowrap;
}

.tooltipSentimentWords {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border-top: 1px solid #C5C5C5;
  color: rgb(163, 163, 163);
  display: flex;
  flex-direction: row;
  margin: 1em -0.2em -0.2em -0.2em;
  white-space: nowrap;
  font-size: 12px;
}

.tooltipSentimentValue {
  font-weight: 900;
}

.tooltipSentimentWord {
  margin: 0.2em;
  white-space: nowrap;
}

/* @media only screen and (max-width: 610px) {
  .appTitle {
    display: none;
  }
} */