.container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.content {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-auto-rows: 20px;
  grid-auto-flow: row;
  padding: 20px;
  width: 300px;
}
.container2 {
  display: grid;
  grid-template-columns: min-content minmax(0, 1fr) min-content;
  grid-auto-rows: 1.8vh;
  grid-auto-flow: row;
  padding: 20px;
}
.row {
  display: contents;
}

.label, .labelRight {
  color: #847C7C;
  text-align: right;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}

.quarter0 .label {
  color: #333;
}
.quarter1 .label {
  color: #999;
}
.quarter2 .label {
  color: #333;
}
.quarter3 .label {
  color: #999;
}

.labelRight {
  justify-content: flex-start;
}

.scoreRow {
  position: relative;
  display: flex;
  flex-direction: row;
  /* display: grid;
  grid-template-columns: max-content; */
  /* grid-gap: 1px; */
  margin: 2px 20px;
}

.bucket {
  opacity: 0.8;
  transition: 200ms;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-auto-rows: 1fr;
  grid-gap: 1px;
  margin: 0 0.5px;

  &:first-child > div {border-radius: 4px 0 0 4px;}
  &:last-child > div {border-radius: 0 4px 4px 0;}

}

.bucket > div {
  height: 100%;
}

.bucket:last-child {
  width: 0 !important;
  flex-grow: 1;
}

.yearSpacer {
  grid-column-start: 1;
  grid-column-end: -1;
}

.header {
  font-size: 12px;
  grid-column-start: 1;
  grid-column-end: -1;
  color: var(--text);
  text-align: center;
}