.topic {
  border-radius: 5px;
  padding: 0 5px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin: 3px;
  white-space: nowrap;
  width: fit-content;
  transition: 200ms;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color, #505050);
  fill: #fff;
  background: var(--background, #e8e3ef);
  border: 1px solid transparent;
}

.topic.hoverable {
  cursor: pointer;
}

.addTopic {
  border: 1px dashed #615E65;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin: 3px;
  white-space: nowrap;
  cursor: pointer;
  transition: 200ms;
  color: #615E65;
}

.topic.hoverable:hover, .topic.selected, .addTopic:hover {
  background: var(--hover-background, var(--primary-color));
  border-color: var(--hover-background, var(--primary-color));
  color: var(--hover-color, #fff);
  fill: var(--hover-color, #fff);
  --icon-tone1: #fff;
  --icon-tone1-opacity: 1;
}

.topic.hoverable:hover .clear {
  fill: #fff;
}

.clearContainer {
  margin: -4px -9px -4px 0;
  padding: 4px 9px 4px 0;
}

.clear {
  width: 0.5em;
  height: 0.5em;
  margin-left: 0.4em;
  transition: 300ms;
  fill: #222;
}

.clearContainer:hover .clear {
  transform: scale(1.5) rotate(90deg);
  fill: #fff;
}

.plus {
  width: 0.5em;
  height: 0.5em;
  margin-right: 0.4em;
  transition: 300ms;
  transform: rotate(45deg);
  fill: #222;
}

.clearContainer:hover .plus {
  transform: scale(1.5) rotate(-45deg);
  fill: #fff;
}

.sentimentRow {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  margin-top: 12px;
}

.sentimentItem {
  background: var(--color);
  text-align: center;
  font-size: 12px;
  padding: 2px 6px;
  transition: 200ms;
  color: #222;
  transition: 200ms;
  cursor: pointer;
}

.sentimentItem:first-child {
  border-radius: 4px 0 0 4px;
}

.sentimentItem:last-child {
  border-radius: 0 4px 4px 0;
}

.sentimentItem:hover, .sentimentItem.selected {
  background: var(--hover-color);
}

.count {
  padding-left: 4px;
}