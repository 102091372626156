.container {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.comments {
  max-width: 40em;
}

.insight {
  background: var(--secondary-w05-color);
  padding: 8px 15px;
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
  margin: 6px 10px -6px 10px;
}

.insightTitle {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: -0.03em;
  color: rgba(255,255,255,.4);
}

.insightDesc {
  font-size: 16px;
  line-height: 23px;
  color: #fff;
  margin: 5px 0;
}
/* 
.container + * {
  margin-top: 0 !important;
  margin-bottom: 50px !important;
  border-radius: 0 0 5px 5px !important;
} */

.insightIcon {
  width: 13px;
  display: inline-block;
  vertical-align: top;
  margin-right: 6px;
}