.images {
  display: grid;
  grid-template-columns: repeat(auto-fill, 83px);
  grid-gap: 5px;
  margin: 20px;
  justify-content: center;
}

.image {
  display: block;
  height: auto;
  object-fit: cover;
  overflow: hidden;
  font-size: 8px;
  // color: #555;
  transition: 300ms;
  box-sizing: border-box;
  position: relative;
}
.image.horizontal {
  grid-column-end: span 2;
}

.fakeImage {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efefef;
  border-radius: 4px;
  height: 83px;
  fill: #ddd;
}

.fakeImage.horizontal {
  grid-column-end: span 2;
}

.image img {
  width: 100%;
  height: auto;
  transition: 300ms;
}

.image:hover img{
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0,0,0,.3);
}

.popupContent {
  max-height: 95%;
}

.popupContent img {
  height: 100%;
  width: auto;
  display: block;
}

.prevIcon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%) rotate(180deg);
  left: 16px;
  z-index: 3;
  fill: #666;
  transition: 200ms;
  cursor: pointer;
}

.nextIcon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 16px;
  z-index: 3;
  fill: #666;
  transition: 200ms;
  cursor: pointer;
}

.prevIcon:hover, .nextIcon:hover {
  fill: var(--primary-color);
}