.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;

  margin-left: var(--nav-width);
  height: 100vh;
  overflow: auto;
  transition: 200ms;

  background: var(--primary-w90-color);
}

.nameSection {
  padding: 1em;
  background: var(--primary-w90-color);
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 200ms;
}

.addBrandSection {
  padding: 1em;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 200ms;
}

.header {
  margin: 0 auto;
  padding: 0;
  font-size: 42px;
  line-height: 1.5em;
  font-weight: 600;
  transition: 200ms;
  overflow: hidden;
  color: #666;
}

.input {
  background: transparent;
  margin-top: 10px;
  border: none;
  color: #222;
  outline: 0;
  padding: 6px 16px;
  font-size: 50px;
  line-height: 1.5em;
  border-radius: 6px;
  transition: 200ms;
  text-align: center;
}

.submit {
  border-radius: 1000em;
  padding: 12px 24px;
  text-align: center;
  outline: 0;
  border: none;
  background: var(--primary-color);
  color: var(--text-color);
  display: block;
  align-self: center;
  font-size: 18px;
  margin-top: 12px;
  transition: 200ms;
}

.submit:disabled {
  background: transparent;
  color: transparent;
} 

.submit:not(:disabled) {
  cursor: pointer;
}
.submit:not(:disabled):hover {
  transform: scale(1.1);
}

.section.minimized {
  flex-grow: 0;
  background: transparent;
}

.nameSection.minimized:hover {
  background: var(--primary-w90-color);
}

.minimized .header {
  height: 0;
  opacity: 0;
  padding: 0;
  margin: 0 auto;
}

.minimized .input {
  background: transparent;
  font-size: 16px;
  padding: 0;
  margin: 0;
  text-align: left;
}

.minimized .submit {
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}


.brandsSection {
  padding: 1em;
  background: var(--primary-w90-color);
  color: #666;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  transition: 200ms;
}

.brand {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: 10px 0;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 6px;
  overflow: hidden;
}

.addBrand {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;
  padding: 8px 12px;
  border: 1px dashed rgba(0,0,0,0.5);
  font-size: 22px;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
}
.addBrand:hover {
  background: #fff;
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.sourceIcon {
  height: 16px;
  width: auto;
  display: block;
  margin: 0 8px;
}

.apps {
  display: flex;
  flex-direction: column;
}

.app {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  --icon-size: 18px;
  min-height: 32px;
  transition: 200ms;
}

.app.clickable {
  cursor: pointer;
}

.app.clickable:hover {
  background: var(--primary-color);
  color: var(--text-color);
}

.appGenres {
  margin: 0 4px;
}

.appTitle {
  margin-left: 6px;
  padding: 0 8px;
  flex-grow: 1;
}

.sourceInput {
  border-radius: 100em;
  padding: 12px;
  border: 1px solid transparent;
  background: transparent;
  outline: 0;
  transition: 200ms;
}

.sourceInput:hover, .sourceInput:focus-within {
  border: 1px solid #aaa;
  background: #fff;
}

.sources {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -4px;
}

.source {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px;
  padding: 8px 12px;
  border: 1px solid #aeaeae;
  background: #fff;
  border-radius: 6px;
}

.source:hover {
  cursor: pointer;
  background: var(--primary-color);
  color: #fff;
  --tone1: #fff;
  --tone2: #fff;
  --tone3: #fff;
  --tone4: #fff;
  --tone5: #fff;
}

.sourceRow {
  width: 100%;
  margin: 12px 4px 2px 4px;
}

.source svg {
  display: block;
  height: 22px;
  width: auto;
  margin-right: 8px;
}



.deleteButton {
  background: none;
  border: none;
  margin: 0;
  display: block;
  padding: 6px;
  height: 32px;
  box-sizing: border-box;
  width: auto;
  transition: 200ms;
  cursor: pointer;
}
.deleteButton svg {
  height: 100%;
  width: auto;
  display: block;
}
.deleteButton:hover {
  background: #ff7575;
  --tone1: #fff;
  --tone2: #fff;
}

.addSourceRow {
  display: flex;
}

.addSource {
  background: var(--primary-color);
  border-radius: 100em;
  color: var(--text-color);
  padding: 6px 12px;
  margin: 6px 8px;
  cursor: pointer;
  transition: 200ms;
}

.addSource:hover {
  background: var(--primary-w30-color);
}

.stars {
  font-size: 12px;
  margin-left: 6px;
}

.addSourceContainer {
  padding: 0 8px;
}

.suggested {
  background: #f9f9e1;
  padding-left: 6px;
  padding-right: 8px;
}

.suggestedLoading {
  background: #f9f9e1;
  padding: 6px 12px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.suggestedError {
  background: #f9f9e1;
  padding: 6px 12px;
  font-size: 12px;
}

.suggestedSpinner {
  height: 8px;
  width: auto;
  flex-grow: 0;
}

.suggestedSpinner svg {
  height: 100%;
  width: auto;
  display: block;
}

.suggestions {
  display: flex;
  flex-direction: column;
}

.suggestedText {
  padding: 0 1em;
  background: #f9f9e1;
  font-size: 12px;
  color: var(--text);
  margin-top: 16px;
  align-self: flex-start;
}