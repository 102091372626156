.container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2fr 3fr;
  min-height: 100vh;
}

.calendly {
  height: 830px;
  width: 100%;
}