.container {
  --size: 60px;
  height: var(--size);
  background: #F9F9F9;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}

.spacer {
  flex-grow: 100000;
}

.pager {
  display: flex;
  flex-direction: row;
}

.pager span {
  font-size: 12px;
  letter-spacing: -0.05em;
  font-weight: bold;
}

.prev span {
  padding-left: 4px;
}
.next span {
  padding-right: 4px;
}

.pager button {
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  background: none;
  border: none;
  fill: #999;
  box-sizing: border-box;
  height: 100%;
  transition: 200ms;
  color: #666;
}

.pager button:not(:disabled) {
  cursor: pointer;
}
.pager button:disabled {
  opacity: 0.3;
}

.prev svg {
  transform: rotate(180deg);
}

.pager button svg {
  display: block;
  height: calc(var(--size)/4);
  width: auto;
}

.pager button:not(:disabled):hover {
  fill: var(--primary-color);
  background: var(--primary-w90-color);
  color: var(--primary-color);
}

.search {
  margin: 10px 16px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  position: relative;
}

.search input {
  flex-grow: 1;
  border-radius: 1000em;
  border: 1px solid #E3DCDC;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
  background: #fff;
  outline: none;
  padding-left: 34px;
  padding-right: 34px;
  font-size: 16px;
  color: var(--text);
  transition: 200ms;
}

.search input:focus {
  outline: 0;
  box-shadow: 0 0 0 1px var(--primary-w50-color);
  border-color: var(--primary-w40-color);
  background: var(--primary-w95-color);
}

.searchIcon {
  position: absolute;
  top: 50%;
  left: 12px;
  height: 18px;
  width: auto;
  transform: translate(0, -50%);
  fill: var(--text);
}

.close {
  position: absolute;
  top: 50%;
  right: 10px;
  height: 22px;
  transform: translate(0, -50%);
  fill: var(--text);
  transition: 200ms;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 4px;
  box-sizing: border-box;
  background: transparent;
}
.close:hover {
  fill: var(--error);
}

.close svg {
  height: 100%;
  width: auto;
  display: block;
}

.label {
  display: flex;
  flex-direction: row;
}
.label span {
  font-weight: 600;
  align-self: center;
  color: #666;
  font-size: 12px;
}
.label select {
  display: block;
  appearance: none;
  font-size: 14px;
  padding: 6px 15px 6px 6px;
  margin-left: 6px;
  color: #666;
  border: 1px solid #999;
  outline: none;
  text-align-last: center;
}

.label .select {
  position: relative;
  align-self: center;
  text-align: right;
}

.label .select:after {
  content: '';
  position: absolute;
  width: 0; 
  height: 0; 
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  
  border-top: 4px solid #999;
  top: 50%;
  right: 4px;
  transform: translate(0, -50%);
  pointer-events: none;
}


.addRow {
  margin: 10px 16px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  border-radius: 1000em;
  background: #fff;
  outline: none;
  font-size: 12px;
  font-weight: bold;
  transition: 200ms;
  align-items: center;
  background: var(--primary-color);
  padding: 0 12px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
}

.addRow + .search {
  margin-left: 0;
}

.addRow:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}