.reviewsHeader {
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  transition: 200ms;


  background: var(--secondary-color);
  height: 60px;
  overflow: hidden;
  position: absolute;
  left: var(--filters-width, 0);
  right: var(--detail-header-width, var(--detail-width, 0));
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 18px;
  color: #fff;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  transition: 200ms;
}
.button svg {
  height: 13px;
  width: auto;
  margin-right: 9px;
  fill: #fff;
}

.button.stacked {
  flex-direction: column;
  font-size: 8px;
}

.button.stacked svg {
  margin-right: 0;
}

.button.stacked .buttonText {
  margin-top: 6px;
}

.button.selected {
  border-bottom: 1px solid #fff;
  background: linear-gradient(0deg, rgba(238, 238, 238, 0), rgba(216, 216, 216, 0.16));
}

.button > * {
  opacity: 0.3;
  transition: 200ms;
}
.button:hover > * {
  opacity: 1;
}

.button.selected > * {
  opacity: 1;
}

.spacer {
  flex-grow: 10000;
}

.bot {
  fill: #fff;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
}
.bot svg {
  height: 17px;
  width: auto;
}

.bot:hover {
  background: linear-gradient(0deg, rgba(238, 238, 238, 0), rgba(216, 216, 216, 0.16));
}