.detail {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  flex-basis: 0;
  margin-top: var(--header-height);
}

.brands {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fill, 120px);
  margin: 10px 20px;
}

.brand {
  cursor: pointer;
  border-radius: 0.5em;
  padding: 6px 6px;
  font-size: 12px;
  --icon-size: 24px;
}

.brand:hover {
  background: var(--primary-color);
  --text: #fff;
}

.brand.selected {
  background: var(--primary-color);
  --text: #fff;
}