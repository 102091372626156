.comment {
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 10px 12px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-shadow: 0 -1px 2px 0px inset rgba(0,0,0,.05);
  overflow: hidden;
  position: relative;
}

.comment:last-child {
  border-bottom: none;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-grow: 1;
}

.header {
  color: #222;
  font-size: 12px;
  font-weight: bold;
  margin-top: 2px;
}

.text {
  color: #717073;
  font-size: 12px;
  line-height: 1.5em;
  margin-top: 3px;
  margin-bottom: 2px;
}

.textarea {
  padding-bottom: 12px;
  font-size: 15px;
  line-height: 1.4;
}

.icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3194F1;
  font-size: 15px;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  margin-top: 2px;
  flex-shrink: 0;
}

.newComment {
  display: flex;
  flex-direction: column;
  position: relative;
}

.newCommentButton {
  font-size: 12px;
  color: #fff;
  padding: 4px 10px;
  background: #47326C;
  border-radius: 10000em;
  white-space: nowrap;
  outline: 0;
  border: none;
  cursor: pointer;
  transition: 200ms;
  margin: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  font-weight: bold;
}

.newCommentButton:hover {
  box-shadow: 1px 2px 1px 0 rgb(45, 30, 70);
  transform: translate(0, -2px);
  color: #fff;
}

.shortcut {
  font-size: 8px;
  font-weight: 500;
  color: rgba(255,255,255,.7);
  display: inline;
  margin-left: 5px;
}

.delete {
  position: absolute;
  right: 12px;
  top: 12px;
  height: 12px;
  width: 12px;
  cursor: pointer;
  transition: 200ms;
}

.delete:hover {
  transform: scale(1.2);
}

.delete svg {
  height: 100%;
  width: 100%;
}