:root {
  --text: #5D5D5D;

  --success: rgb(124, 255, 117);

  --error: #ff7575;
  --error-border: rgba(255, 117, 117, 0.3);
  --error-text: rgb(227, 42, 42);
  --error-input: rgb(255, 212, 212);

  --input-border: #D8D8D8;

  --accent-hover: rgba(121, 117, 255, 0.05);
  --accent-input: #ffffee;
  --accent-text: #fff;
  --accent-disabled: #e1e1e1;

  --background: #E7E7E7;
  --content: #fff;
  --nav-text: #D8D8D8;
  
  --nav-width: 50px;
  --nav-width-expanded: 300px;
  --box-shadow: rgba(0,0,0,0.2);

  --icon-tone1: #000;
  --icon-tone2: #000;
  --icon-tone1-opacity: 0.5;
  --icon-tone2-opacity: 0.2;

  --icon-hover-tone1: #524F9F;
  --icon-hover-tone2: #524F9F;
  --icon-hover-tone1-opacity: 0.8;
  --icon-hover-tone2-opacity: 0.4;

  --highlight: rgb(255, 255, 231);

  --font: 'Readex Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Droid Sans", "Helvetica Neue", sans-serif;
  --font-code: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;

}

html, body {
  padding: 0;
  margin: 0;
  /* height: 100%; */
  overscroll-behavior: none;
}


body {
  margin: 0;
  padding: 0;
  /* height: 100%; */
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --nav-closed-width: 52px;
  --nav-opened-width: 224px;
  --nav-width: var(--nav-closed-width);


  --filters-closed-width: 52px;
  --filters-opened-width: 268px;
  --filters-width: var(--filters-closed-width);

  --header-closed-height: 0;
  --header-opened-height: 32px;

  --tone1-default: rgba(0,0,0,0.5);
  --tone2-default: rgba(0,0,0,0.2);
  --tone1-opacity-default: 1;
  --tone2-opacity-default: 1;
}

code {
  font-family: var(--font-code);
}

input, textarea, button {
  min-width: 0;
  font-family: var(--font);
  font-size: 1em;
}

a:link, a:visited {
  /* this interferes everywhere since it's too global */
  /* color: #586d9f; */
  text-decoration: none;
}
a:link:hover, a:visited:hover {
  transition: 0.3s;
  /* color: var(--icon-hover-tone1); */
}

:global(#root) {
  /* height: 100%; */
  visibility: visible;
  /* display: grid;
  grid-auto-columns: 100%;
  grid-auto-rows: 100%; */
}

:global(.StripeElement) {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

:global(.StripeElement--focus) {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

:global(.StripeElement.PaymentRequestButton) {
  padding: 0;
}

:global(#drift-widget-container) {
  display: none;
} 

:global(.showDrift) :global(#drift-widget-container) {
  display: block;
} 

@media (max-width: 610px) {
  :global(#drift-widget-container) {
    display: none;
  }
}


:global(.dragging) * {
  user-select: none;
}