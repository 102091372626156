.concepts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -3px;
}

.header {
  color: var(--text);
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
}

.header button {
  border-radius: 4px;
  border: 1px solid #ccc;
  color: var(--text);
  background: #fff;
  cursor: pointer;
  margin-left: 6px;
  padding: 2px 4px;
  font-size: 10px;
  outline: none;
}

.header button:hover {
  background: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.brands {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}


.icon {
  margin: 0.25em 0.25em;
  opacity: 0.5;
  cursor: pointer;
}

.icon:hover {
  opacity: 1;
}

.groups {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-auto-rows: min-content;
  grid-gap: 6px;
  margin-bottom: 6px;
}

.groupName {
  color: var(--text);
  font-size: 14px;
  padding: 6px 6px 6px 0;
}

.groupValues {
  border-radius: 4px;
  border: 1px solid var(--input-border);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  padding: 4px;
}

.deleteIcon svg {
  cursor: pointer;
  
}

.deleteIcon svg:hover {
  --icon-tone1: var(--primary-color);
}