.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttons span {
  margin: 0 4px;
  font-weight: 600;
  font-size: 14px;
  color: #333;
}

.button {
  color: #333;
  font-weight: 600;
  background: #eee;
  padding: 4px 6px;
  border-radius: 4px;
  box-shadow: 0 3px 0 #aaa;
  font-size: 10px;
}