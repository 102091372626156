.container {
  position: relative;
  margin: 0;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: var(--filters-width, 0);
  margin-right: var(--detail-width, 0);
  transition: 200ms;
}

.header {
  background: var(--secondary-color);
  height: 60px;
}

.detail {
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 60px;
  bottom: var(--footer-height, 0);
  width: var(--detail-width, 0);
}

.detailHeader {
  height: 60px;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--detail-header-width, var(--detail-width, 0));
}

.mainSection {
  position: relative;
  overflow: hidden;
  background: var(--primary-w90-color);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.5em;
}