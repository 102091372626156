.container {
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
  overflow: hidden;
  background: var(--primary-w98-color, #F9F9F9);
}

.header {
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 45px 50px 0 50px;
  background: #F9F9F9;
  background: var(--primary-w98-color, #F9F9F9);
  left: 0;
  right: 0;
  transition: 200ms;
}

.header.hidden {
  opacity: 0;
  transform: translate(0, -100%);
  pointer-events: none;
}

.header h2 {
  font-weight: bold;
  font-size: 13px;
  color: #C4C4C4;
  letter-spacing: 1.3px;
}

.logo {
  height: 29px;
  width: auto;
}

/* @media screen and (prefers-color-scheme: dark) {
  .header {
    background: #1E1E1E;
  }
} */

@media screen and (max-width: 767px) {
  .header {
    padding-top: 15px;
  }
}
