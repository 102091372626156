.container {
  position: fixed;
  bottom: 0;
  transform: translate(-50%, 100%);
  pointer-events: none;
  z-index: 10000;
  left: 50%;
  transition: 200ms;
}

.container.showing {
  opacity: 0.9;
  transform: translate(-50%, 0);
}

.content {
  background: var(--background-color, #111);
  color: #fff;
  padding: 0.5em;
  border-radius: 0.5em;
  filter: drop-shadow(0 2px 4px var(--shadow-color));
  white-space: nowrap;
  font-size: 16px;
  padding: .5em 1em;
  opacity: 0.95;
  margin-bottom: 1em;
}

@keyframes slide-up {
  0% {
      opacity: 0;
      transform: translate(-50%, 100%);
  }
  100% {
      opacity: 0.9;
      transform: translate(-50%, 0);
  }
}