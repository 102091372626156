
@font-face {
  font-family: 'Readex Pro';
  src: url('./readexpro/ReadexPro-Regular.woff') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Readex Pro';
  src: url('./readexpro/ReadexPro-Light.woff') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Readex Pro';
  src: url('./readexpro/ReadexPro-Bold.woff') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Readex Pro';
  src: url('./readexpro/ReadexPro-SemiBold.woff') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Readex Pro';
  src: url('./readexpro/ReadexPro-ExtraLight.woff') format('woff2');
  font-weight: 100;
  font-style: normal;
}


@font-face {
  font-family: 'tiempos';
  src: url('./tiempos/TiemposHeadline-Semibold.woff') format('woff'),
  url('./tiempos/TiemposHeadline-Semibold.ttf') format('truetype');
}