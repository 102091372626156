.detail {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}

.scrollable {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}

.description {
  margin: 20px;
  font-size: 12px;
  line-height: 17px;
  color: #100C18;
}


.sectionHeader {
  font-size: 14px;
  font-weight: 600;
  color: #636169;
  line-height: 20px;
  padding: 0 6px;
  margin: 0 17px 0 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0;
}

.sectionHeader:first-child {
  margin-top: 26px;
}
.sectionHeader span {
  margin-right: 6px;
}
.sectionHeader strong {
  margin-left: 6px;
}

.wordLabel, .wordLabel input {
  font-weight: 600;
  color: #636169;
  line-height: 20px;
  padding: 0;
}
.wordLabel {
  margin-right: 6px;
}

.section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px 17px 17px 17px;
  flex-shrink: 0;
}

.superString {
  color: red;
}

.spacer {
  flex-grow: 10000;
}

.deleteButton {
  margin: 6px 20px 6px 20px;
  padding: 8px;
  border-radius: 4px;
  background: #EF5A46;
  text-align: center;
  color: #FBDEDA;
  cursor: pointer;
  transition: 200ms;
}

.deleteButton:hover {
  background: #BF4838;
}

.button {
  margin: 6px 20px 6px 20px;
  padding: 8px;
  border-radius: 4px;
  background: #7471b0;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: 200ms;
  font-size: 13px;
  letter-spacing: -0.02em;
}

.button:hover {
  background: rgb(126, 56, 191);
}

.label {
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text);
  margin-right: 6px;
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.5em;
}

.label:before {
  content: '';
  width: 6px;
  height: 6px;
  background: var(--color);
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 2px;
  margin-top: 2px;
  border: 1px solid rgba(0,0,0,0.2);
}

.clipboard {
  font-size: 10px;
  flex-grow: 1;
}

.phrase {
  margin: 1px 0;
  background: var(--color);
  border-bottom: 1px solid var(--color);
  border-radius: 4px;
  padding: 0.1em 0.3em 0.035em;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.6em;
}


.suggestConcepts {
  margin: 5px 0px 10px 0px;
}

.suggestConceptWrap {
  margin: 10px 17px;
}
.suggestConceptWrap .clipboard {
  margin: 0 10px 0 10px;
  transform: translate(0, -50%);
}

.suggestConcept {
  padding: 10px;
  border-radius: 6px;
  position: relative;
}

.conceptName {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #444;
}
.conceptDelete {
  font-size: 8px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 6px;
  border-radius: 100000em;
  background: #FFD6D5;
  color: var(--error-text);
  z-index: 10;
  transform: translate(6px, -50%);
  transition: 200ms;
  cursor: pointer;
}
.conceptDelete:hover {
  background: rgb(252, 163, 163);
}

.suggestConceptWords {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.5em -4px;
}

.suggestConceptWordsTitle {
  font-size: 11px;
  letter-spacing: 0.03em;
  color: #444;
}

.suggestConceptWord {
  margin: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 300;
  padding: 2px 7px;
}

.superString {
  color: red;
}

.suggestConceptCreate {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #ddd;
  transition: 200ms;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.suggestConceptCreate:hover {
  background: rgba(82, 79, 159, 0.2);
}

.suggestConceptCreateButton {
  background: #eee;
  border-radius: 6px;
  padding: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  pointer-events: none;
  opacity: 0;
  transition: 200ms;
  font-size: 13px;
}

.suggestConceptCreate:hover .suggestConceptCreateButton {
  opacity: 1;
}

.checkboxLabel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px 17px 17px 17px;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 6px;
  flex-shrink: 0;
}

.checkboxLabelText {
  color: #666;
  font-size: 14px;
}