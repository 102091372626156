.detail {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}

.scrollable {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}


.tabs {
  margin-top: 26px;
  padding: 0 17px;
  border-bottom: 1px solid #EAEAEA;
  display: flex;
  flex-direction: row;
}

.tab {
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 0;
  font-size: 10px;
  font-weight: bold;
  color: #585858;
  letter-spacing: 0.83px;
  text-align: center;
  padding: 3px 2px;
  border-bottom: 4px solid transparent;
  transition: 200ms;
}
.tab:link, .tab:visited {
  color: #585858;
  text-decoration: none;
}
.tab.selected {
  border-color: #ECE8F2;
}

.userRow {
  display: grid;
  grid-auto-columns: max-content 1fr max-content;
  grid-auto-rows: min-content min-content;
  padding: 10px;
  grid-gap: 2px 4px;
}
.userRowCircle {
  position: relative;
  width: 9px;
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 2;
}

.circle {
  position: relative;
  background: #fff;
  border-radius: 10000em;
  width: 5px;
  height: 5px;
  border: 2px solid #D9D9D9;
  z-index: 1;
  margin-top: 2px;
}
.circleBar {
  position: absolute;
  width: 1px;
  top: 16px;
  bottom: -12px;
  left: 50%;
  background: #DFDFDF;
  transform: translate(-2px, 0);
  z-index: 0;
}

.userRowTitle {
  font-size: 11px;
  letter-spacing: -0.25px;
  color: #585858;
  font-weight: bold;
  grid-column-start: 2;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
}

.userRowSubtitle {
  font-size: 11px;
  letter-spacing: -0.25px;
  color: #9F9B9B;
  grid-column-start: 2;
  grid-column-end: span 1;
  grid-row-start: 2;
  grid-row-end: span 1;
}

.userRowDate {
  font-size: 11px;
  letter-spacing: -0.25px;
  color: #9F9B9B;
  grid-column-start: 3;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
}

.userRow:last-child .circleBar {
  display: none;
}